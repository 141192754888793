// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  Logger,
  createContextPropagationInterceptor,
} from '@imprivata-cloud/common';
import type {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { tracer } from '../tracing';
import { client } from './client';

let requestInterceptors: number[] = [];
let responseInterceptors: number[] = [];

export const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  createContextPropagationInterceptor(tracer);
  return config;
};

export const onRequestError = async (
  error: AxiosError,
): Promise<AxiosError> => {
  return Promise.reject(error);
};

export const onResponse = async <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  response: AxiosResponse<T>,
): Promise<T> => {
  Logger.response(response);
  if (response.data.error) {
    return Promise.reject(response.data.error);
  }
  return Promise.resolve(response.data);
};

export const onResponseError = async (
  error: AxiosError<Record<string, unknown>>,
): Promise<AxiosError> => {
  Logger.error(error);
  return Promise.reject(error);
};

export const requestLoggingInterceptor = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  Logger.request(config);
  return config;
};

export const applyInterceptors = () => {
  requestInterceptors.push(
    client.interceptors.request.use(requestLoggingInterceptor),
  );
  requestInterceptors.push(
    client.interceptors.request.use(onRequest, onRequestError),
  );

  responseInterceptors.push(
    client.interceptors.response.use(onResponse, onResponseError),
  );
};

export const ejectInterceptors = (): void => {
  requestInterceptors.forEach(interceptor => {
    client.interceptors.request.eject(interceptor);
  });

  responseInterceptors.forEach(interceptor => {
    client.interceptors.response.eject(interceptor);
  });

  requestInterceptors = [];
  responseInterceptors = [];
};
