// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { AuthnMethod } from '@imprivata-cloud/authn';
import type {
  AppAuthenticationCanceledEventHandler,
  AppAuthenticationStatusEventHandler,
  FactorDataReadyEventHandler,
  CloseWindowEventHandler,
  ErrorEventHandler,
  FactorChangedEventHandler,
  InternetConnectivityStatusHandler,
} from '../../types';
import type { ProxReader } from '../devices';
import type {
  ComponentData,
  ComponentId,
  SessionData,
} from '../../agent-api/types';
import type { AgentEvent } from '../../constants';
import { ShowWindowOption, WindowStyle } from '../../agent-api/types';

export class MemorySection {
  registeredCallbacks: {
    [AgentEvent.FACTOR_DATA_READY]?: FactorDataReadyEventHandler;
    [AgentEvent.FACTOR_CHANGED]?: FactorChangedEventHandler;
    [AgentEvent.ERROR]?: ErrorEventHandler;
    [AgentEvent.CLOSE_WINDOW]?: CloseWindowEventHandler;
    [AgentEvent.APP_AUTHENTICATION_STATUS]?: AppAuthenticationStatusEventHandler;
    [AgentEvent.APP_AUTHENTICATION_CANCELED]?: AppAuthenticationCanceledEventHandler;
    [AgentEvent.NETWORK_CONNECTIVITY_CHANGED]?: InternetConnectivityStatusHandler;
  } = {};

  connectedDevices = new Map<AuthnMethod, ProxReader>();
  submittedCredentials: CredentialData[] = [];
  submittedOidcAuthCodes: string[] = [];

  appAccessContext = '';

  codeChallenge = '';
  codeChallengeMethod = '';

  identityToken = '';
  codingContext = '';
  systemData = '';
  sessionData: SessionData = [];
  agentComponents = new Map<ComponentId, ComponentData>();

  // Can be used for local testing:
  // appAccessContext = jsonToBase64({ username: 'imprid' });

  // showWindowOption: ShowWindowOption = ShowWindowOption.show;
  showWindowOption: ShowWindowOption = ShowWindowOption.hide;
  onShowWindowChangeCallback?: (showWindowOption: ShowWindowOption) => void;

  setWindowStyle: WindowStyle = WindowStyle.border;
  onSetWindowStyleChangeCallback?: (setWindowStyle: WindowStyle) => void;
}
