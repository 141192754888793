export const QueryParams = {
  TENANT_ID: 'tenantId',
  CONTEXT_TYPE: 'contextType',
  RESOURCE_TYPE: 'resourceType',
  AUTHN_API_VERSION: 'authnApiVersion', // for testing
};

// API version for authn and enroll
export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}
