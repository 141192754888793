// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import type { AuthBannerProps } from '@imprivata-cloud/components';
import type { AuthnRequest, OidcRequestData } from '@imprivata-cloud/authn';
import type { CodingContext } from '@imprivata-cloud/data-privacy-js';

import type {
  AppAccessContext,
  ProcessCapturedCredentialsActionData,
  ReadSessionDataRequest,
  UiCaptureCredentialsActionData,
} from './types';
import type { ComponentId } from '../agent-api/types';
import type { AuthenticationStatusCode } from '../types';
import type { InternetConnectivityStatus } from '../constants';

export const errorAction = createAction('error')();

export const resetStateAction = createAction(
  'resetStateAction',
)<PartialBannerProps | null>();

export type PartialBannerProps = Partial<AuthBannerProps> & {
  errorCode?: string;
};

export const subscribeToAuthResponseAction = createAction(
  'app_init/SUBSCRIBE_TO_AUTH_RESPONSE',
)();

export const readAppAccessContext = createAction('read-app-access-context')();
export const saveAppAccessContext = createAction(
  'save-app-access-context',
)<AppAccessContext>();

export const setAuthenticationStatusAction = createAction(
  'set-authentication-status',
)<AuthenticationStatusCode>();

export const startApplicationAccessAction = createAsyncAction(
  'startApplicationAccess/REQUEST',
  'startApplicationAccess/SUCCESS',
  'startApplicationAccess/FAILURE',
)<AppAccessServiceData, void, void>();

export const getCredentialsAction = createAsyncAction(
  'getCredentials/REQUEST',
  'getCredentials/SUCCESS',
  'getCredentials/FAILURE',
)<AppAccessServiceData, void, void>();

export const uiCaptureCredentialsAction = createAction(
  'uiCaptureCredentialsAction',
)<UiCaptureCredentialsActionData>();

export const processCapturedCredentialsAction = createAsyncAction(
  'processCapturedCredentials/REQUEST',
  'processCapturedCredentials/SUCCESS',
  'processCapturedCredentials/FAILURE',
)<ProcessCapturedCredentialsActionData, void, void>();

export const processLoginResultAction = createAsyncAction(
  'processLoginResult/REQUEST',
  'processLoginResult/SUCCESS',
  'processLoginResult/FAILURE',
)<AuthenticationStatusCode, void, void>();

export const readPkceData = createAction('read-pkce-data')();
export const savePkceData = createAction('save-pkce-data')<OidcRequestData>();

export const readComponentData = createAction(
  'read-component-data',
)<ComponentId>();

export const clearError = createAction('clear-error')();

export const readIdentityTokenDataAction = createAsyncAction(
  'readIdentityTokenDataAction/REQUEST',
  'readIdentityTokenDataAction/SUCCESS',
  'readIdentityTokenDataAction/FAILURE',
)<void, void, void>();
export const saveAuthnDataAction =
  createAction('save-authn-data')<AuthnRequest>();
export const resetAuthnDataAction = createAction('reset-authn-data')();

export const readSessionDataAction = createAsyncAction(
  'readSessionDataAction/REQUEST',
  'readSessionDataAction/SUCCESS',
  'readSessionDataAction/FAILURE',
)<ReadSessionDataRequest, void, void>();

export const saveAgentSaml = createAction('save-agent-saml')<string>();
export const saveCodingContextAction = createAction(
  'save-coding-context',
)<CodingContext>();
export const isSamlAuthenticationAction = createAction(
  'is-saml-authentication',
)<boolean>();
export const setInternetConnectivityStatusAction = createAction(
  'set-internet-connectivity-status',
)<InternetConnectivityStatus>();
