// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common/types';
import { tracer } from '../../tracing';

export class OtelSection {
  getTraceContext(_: string, callback: (traceContext: TraceContext) => void) {
    tracer.startSpan('get_trace_context');
    callback(tracer.getTraceContext());
    tracer.endSpan('get_trace_context');
  }
}
