// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Space } from 'antd';
import { InputBox, Button, ButtonVariant } from '@imprivata-cloud/components';
import { AuthnMethod } from '@imprivata-cloud/authn';

import { usePersistentSessionState } from '../../hooks';
import { log } from '../../utils';
import { Section } from '../components/Section';
import { AgentEvent } from '../../../constants';

export const ProxReader: React.FC<{
  isConnected: boolean;
  onChange: () => void;
}> = props => {
  const [cardNumber, setCardNumber] = usePersistentSessionState({
    tu__card_number: '',
  });

  const connectDevice = () => {
    log('window.impr?._config', {
      props,
      config: window.impr?._config,
    });

    if (props.isConnected) {
      log('disconnecting device');
      window.impr?._config.simulateDisconnectDevice(AuthnMethod.PROX);
    } else {
      log('connecting device');
      window.impr?._config.simulateConnectDevice(AuthnMethod.PROX);
    }
    props.onChange();
  };

  return (
    <Section
      data-testid="tu--prox-reader-section"
      header={'ProxReader'}
      headerButton={
        <Button
          data-testid="tu--prox-reader-connect-button"
          onClick={connectDevice}
          variant={ButtonVariant.LINK}
        >
          {props.isConnected ? 'Disconnect' : 'Connect'}
        </Button>
      }
    >
      <Space>
        <InputBox
          size="small"
          disabled={!props.isConnected}
          data-testid="tu--card-number-input"
          id="tu--card-number-input"
          type="text"
          value={cardNumber.tu__card_number}
          onChange={e => {
            setCardNumber({
              tu__card_number: e.target.value,
            });
          }}
          placeholder="Prox number"
        />

        <Button
          size="major"
          data-testid="tu--prox-reader-interact-button"
          disabled={!props.isConnected}
          onClick={() => {
            console.debug('[ProxReader:simulateDeviceInteraction]');
            window.impr?._config.simulateDeviceInteraction(
              AuthnMethod.PROX,
              AgentEvent.FACTOR_DATA_READY,
              {
                cardNumber:
                  (
                    document.querySelector(
                      'input#tu--card-number-input',
                    ) as HTMLInputElement
                  ).value || '',
              },
            );
          }}
        >
          Interact
        </Button>
      </Space>
    </Section>
  );
};
