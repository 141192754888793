// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvide } from 'react-redux';
import Main from './Main';
import { LocaleProvider } from './i18n/localeProvider';
import { attachAgentMockIfNeeded } from './endpoint-agent-mock';
import { configureStore } from './store/configureStore';

attachAgentMockIfNeeded();

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvide store={store}>
      <LocaleProvider>
        <Main />
      </LocaleProvider>
    </ReduxProvide>
  </React.StrictMode>,
  document.getElementById('root'),
);
