// Copyright 2023, Imprivata, Inc.  All rights reserved.
import { createContextFromImprCodingCtxHeader } from '@imprivata-cloud/data-privacy-js';
import { isActionOf } from 'typesafe-actions';
import { catchError, filter, from, of, switchMap } from 'rxjs';
import type { Epic } from 'redux-observable';
import type { Action, EpicDependencies, RootState } from '../types';
import type { ApiError } from '../../api/types';
import { getCredentialsService } from '../../services/getCredentials';
import { SpanNames, tracer } from '../../tracing';
import {
  getCredentialsAction,
  isSamlAuthenticationAction,
  uiCaptureCredentialsAction,
} from '../actions';
import { showWindow } from '../../agent-api';
import { ShowWindowOption } from '../../agent-api/types';
import { debug } from '../../utils/logging';

export const getCredentialsEpic: Epic<
  Action,
  Action,
  RootState,
  Partial<EpicDependencies>
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(getCredentialsAction.request)),
    switchMap(({ payload }) => {
      tracer.startSpan(SpanNames.GET_USER_APP_CREDENTIALS);

      const { oidcAuthorizationCode } = payload;

      // [APM-893]: Temporary fallback to localStorage
      let codingContext = state$.value.appAccessContext?.codingContext;

      if (!codingContext) {
        const storedCodingContext = localStorage.getItem('codingContext');
        codingContext =
          (storedCodingContext &&
            createContextFromImprCodingCtxHeader(storedCodingContext)) ||
          undefined;
      }

      return from(
        getCredentialsService({
          oidcAuthorizationCode,
          codingContext,
          tracer,
        }),
      ).pipe(
        switchMap(response => {
          if (typeof response === 'object') {
            showWindow(ShowWindowOption.show);
            return of(uiCaptureCredentialsAction(response));
          } else {
            return of(
              getCredentialsAction.success(),
              isSamlAuthenticationAction(response),
            );
          }
        }),
        catchError((error: ApiError) => {
          debug('[GetCredentials] error', { error });
          return of(getCredentialsAction.failure());
        }),
      );
    }),
  );
};
