// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useMemo, useState } from 'react';
import { Alert, Space, Typography } from 'antd';
import { Button } from '@imprivata-cloud/components';

import { AuthnMethod } from '@imprivata-cloud/authn';
import { ProxReader } from '../devices';
import {
  AuthenticationStatusSection,
  AppContextSection,
  SystemDataSection,
  SessionDataSection,
} from '../sections';
import { UiControl } from '../sections/UiControl';
import { OfflineModeSection } from '../sections/OfflineModeSection';
import { log } from '../../utils';

export const TestUtilityWindow: React.FC<{ onClose?: () => void }> = props => {
  const connectedDevicesAtStartup = useMemo(() => {
    return window.impr?._config.getConnectedDevices();
  }, []);

  const [connectedDevices, setConnectedDevices] = useState<
    AuthnMethod[] | undefined
  >(connectedDevicesAtStartup);

  // TODO: Remove
  log('connectedDevices', connectedDevices, connectedDevicesAtStartup);

  const onDeviceConnectionChange = () => {
    setConnectedDevices(window.impr?._config.getConnectedDevices());
  };

  return (
    <div
      data-testid="tu--window"
      style={{
        background: 'var(--white)',
        border: '1px solid var(--swagger)',
        borderRadius: 6,
        boxShadow: '0 0 6px 4px rgb(0 0 0 / 27%)',
        display: 'flex',
        flexDirection: 'column',
        justifySelf: 'flex-end',
        minWidth: 'fit-content',
        pointerEvents: 'auto',
      }}
    >
      {/* Header */}
      <Alert
        type="info"
        onClose={props.onClose}
        message={
          <Space direction="horizontal">
            <Typography.Text strong>Test Utility</Typography.Text>
            <Button
              variant="link"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = window.location.origin;
              }}
            >
              Clear Session Storage
            </Button>
          </Space>
        }
        closable
      />
      {/* Sections */}
      <Space
        direction="vertical"
        style={{ overflowY: 'auto', marginBottom: 80, padding: '16px 0' }}
        size="small"
      >
        <UiControl />
        <ProxReader
          isConnected={!!connectedDevices?.includes(AuthnMethod.PROX)}
          onChange={onDeviceConnectionChange}
        />
        <AuthenticationStatusSection />
        <AppContextSection />
        <OfflineModeSection />
        <SystemDataSection />
        <SessionDataSection />
      </Space>
    </div>
  );
};
