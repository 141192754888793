// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import type { Store } from 'redux';
import type { RecursivePartial } from '../utils/types';
import type { Action, EpicDependencies, RootState } from './types';
import {
  authenticationStatus$,
  endJourneyAndCloseWindow,
  sendAuthnDataToAgent,
  setAuthenticationStatus,
  showWindow,
} from '../agent-api';
import {
  getCredentials,
  processCapturedCredentials,
  processLoginResult,
  startApplicationAccess,
} from '../api/appAccessWebService';
import { rootEpic } from './epics';
import reducer, { initialState } from './reducer';

const isProduction = process.env.NODE_ENV === 'production';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const composeEnhancers =
  (!isProduction &&
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Hosting App' })) ||
  compose;

export const configureStore = (): Store<RootState> => {
  const epicMiddleware = createEpicMiddleware<
    Action,
    Action,
    RootState,
    EpicDependencies
  >({
    dependencies: {
      authenticationStatus$,
      endJourneyAndCloseWindow,
      showWindow,
      sendAuthnDataToAgent,
      setAuthenticationStatus,
      startApplicationAccess,
      getCredentials,
      processCapturedCredentials,
      processLoginResult,
    },
  });

  const store = createStore(
    reducer,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  return store;
};

// to be used in unit tests
export const createShallowStore = (
  partialState: RecursivePartial<RootState> = {},
): Store<RootState> => {
  const state = { ...initialState, ...partialState };

  return createStore(reducer, state);
};
