// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { Secret } from '@imprivata-cloud/data-privacy-js';
import type { AuthenticationStatusCode } from '../types';

export interface ApiError {
  code: string;
  message?: string;
  type?: unknown;
  data?: unknown;
}

export type B3Descriptor = string;
export interface TraceContext {
  spanContext: B3Descriptor;
  journeyID: string | null;
}

// CapturedCredentialsProcess
export interface CapturedCredentialsProcessRequest {
  username?: string;
  password: Secret;
  domain?: string; // Not used for ECC
}

export interface CapturedCredentialsProcessResponse {
  nextAction: 'verify-credentials';
  credentialData: Secret;
}

// LoginResultProcess
export interface LoginResultProcessRequest {
  verifyStatus: AuthenticationStatusCode;
}

export interface LoginResultProcessResponse {
  nextAction: LoginResultProcessResponseNextAction;
  usernameHint?: string;
  aadUpn?: string;
}

export enum LoginResultProcessResponseNextAction {
  CAPTURE_CREDENTIALS = 'capture-credentials',
  CAPTURE_PASSWORD = 'capture-password',
  NONE = 'none',
}
