// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { catchError, filter, from, of, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import type { Epic } from 'redux-observable';
import type { Action, EpicDependencies, RootState } from '../types';
import type { ApiError } from '../../api/types';
import { ApplicationAccessStartResponseNextAction } from '../../shared/types/api';
import {
  getCredentialsAction,
  saveCodingContextAction,
  setAuthenticationStatusAction,
  startApplicationAccessAction,
} from '../actions';
import { SpanNames, tracer } from '../../tracing';
import { sendAuthnDataToAgent } from '../../agent-api';
import {
  isSharedWorkstation,
  normalizeSamlDataForEpicOauth,
} from '../../utils/utils';
import { AuthenticationResultStatusCode } from '../../constants';

export const startApplicationAccessEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'startApplicationAccess'>
> = (action$, state$, dependencies) => {
  const { startApplicationAccess } = dependencies;

  return action$.pipe(
    filter(isActionOf(startApplicationAccessAction.request)),
    switchMap(({ payload }) => {
      tracer.startSpan(SpanNames.START_CLIENT_USER_SESSION);
      const { agentSaml, appAccessContext } = state$.value;

      const { baseUrl, fhirServerUrl } = appAccessContext || {};

      const {
        contextType,
        resourceType,
        codingContext,
        oidcAuthorizationCode,
        identityToken,
      } = payload;

      let samlData;

      // TODO: Remove or replace when Epic Oauth flow is clarified
      const { samlAccepted, samlRequired } =
        normalizeSamlDataForEpicOauth(appAccessContext?.samlData) || {};

      if (appAccessContext?.samlData) {
        samlData = jsonToBase64(appAccessContext.samlData);
      }

      if (isSharedWorkstation(appAccessContext)) {
        sendAuthnDataToAgent(
          {
            username: appAccessContext?.username,
            password: appAccessContext?.password,
            domain: appAccessContext?.domain,
          },
          oidcAuthorizationCode,
        );
        return of(getCredentialsAction.success());
      }

      return from(
        startApplicationAccess(
          {
            contextType,
            resourceType,
            epicData: {
              appSamlData: agentSaml || samlData || '',
              samlAccepted,
              samlRequired,
              interconnectBaseUrl: baseUrl,
              fhirServerUrl,
            },
          },
          codingContext,
          identityToken,
        ),
      ).pipe(
        switchMap(response => {
          tracer.endSpan(SpanNames.START_CLIENT_USER_SESSION, { response });
          if (
            response?.nextAction ===
            ApplicationAccessStartResponseNextAction.GET_APP_CREDENTIALS
          ) {
            if (codingContext) {
              return from([
                saveCodingContextAction(codingContext),
                getCredentialsAction.request(payload),
              ]);
            } else {
              return of(getCredentialsAction.request(payload));
            }
          } else {
            return of(startApplicationAccessAction.failure());
          }
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(SpanNames.START_CLIENT_USER_SESSION, { error });
          return of(
            setAuthenticationStatusAction(
              AuthenticationResultStatusCode.FAILURE,
            ),
            startApplicationAccessAction.failure(),
          );
        }),
      );
    }),
  );
};
