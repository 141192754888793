// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { _getEnvVariable, _getEnv } from '@imprivata-cloud/common';
import { ApiVersion, QueryParams } from './utils/constants';
// NOTE: This is temporary until feature flags have been properly implemented
import featureFlags from './featureFlags.json';

export type FeatureFlags = typeof featureFlags;

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export interface AppEnvConfig {
  API_URL: string;
  AUTHN_API_VERSION: ApiVersion;
  ZIPKIN_TRACING_URL?: string;
  OTLP_TRACING_URL?: string;
  COLLECT_TRACING?: string;
  TEST_UTILITY_ENABLED?: boolean;
  FACTOR_SWITCH_ENABLED?: boolean;
  TRACING_SOLUTION_TYPE?: string;
}

let envConfig: AppEnvConfig;

export function _initAppConfig(
  window: Window,
  env: ImportMetaEnv,
): AppEnvConfig {
  let authnApiVersion: ApiVersion;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(QueryParams.AUTHN_API_VERSION)) {
    authnApiVersion = urlParams.get(
      QueryParams.AUTHN_API_VERSION,
    ) as ApiVersion;
  } else {
    authnApiVersion = _getEnvVariable(
      window,
      env,
      'API_VERSION',
      ApiVersion.V2,
    ) as ApiVersion;
  }

  const { apiUrl, tracingUrl, currentEnv } = _getEnv();

  let features: Partial<AppEnvConfig> = featureFlags['prod'];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  console.debug(
    '[AppAccess:appConfigUtils] feature flags: ',
    JSON.stringify({
      currentEnv,
      features,
    }),
  );

  return {
    API_URL:
      apiUrl || (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    AUTHN_API_VERSION: authnApiVersion,
    ZIPKIN_TRACING_URL: _getEnvVariable(window, env, 'ZIPKIN_TRACING_URL'),
    OTLP_TRACING_URL: _getEnvVariable(
      window,
      env,
      'OTLP_TRACING_URL',
      tracingUrl,
    ),
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING'),
    ...features,
  };
}

export default function getConfig(): AppEnvConfig {
  if (envConfig === undefined) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
