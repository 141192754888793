// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useState } from 'react';
import { Tooltip } from 'antd';
import { InputBox } from '@imprivata-cloud/components';
import { base64ToString, jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import { usePersistentSessionState } from '../../hooks';
import { Section } from '../components/Section';

export const AppContextSection = () => {
  const [state, setState] = usePersistentSessionState({
    app_access_context: '',
  });
  const [encodingError, setEncodingError] = useState(false);

  return (
    <Section header="AppContext">
      <InputBox
        type="text"
        placeholder="App Access Context"
        status={encodingError ? 'error' : ''}
        defaultValue={base64ToString(state.app_access_context)}
        onChange={event => {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const encodedContext = JSON.parse(event.target.value);
            setState({
              app_access_context: jsonToBase64(encodedContext),
            });
            setEncodingError(false);
          } catch {
            setEncodingError(true);
          }
        }}
      />

      {encodingError && (
        <Tooltip title="String is not in correct JSON format">
          <span>(!)</span>
        </Tooltip>
      )}
    </Section>
  );
};
