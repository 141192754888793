// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { AuthnRequest, OidcRequestData } from '@imprivata-cloud/authn';
import type { CodingContext } from '@imprivata-cloud/data-privacy-js';
import type { Option } from 'fp-ts/Option';
import type { ActionType } from 'typesafe-actions';
import type { Observable } from 'rxjs';
import type {
  getCredentials,
  processCapturedCredentials,
  processLoginResult,
  startApplicationAccess,
} from '../api/appAccessWebService';
import type {
  authenticationStatus$,
  endJourneyAndCloseWindow,
  sendAuthnDataToAgent,
  setAuthenticationStatus,
  showWindow,
} from '../agent-api';
import type * as actions from './actions';
import type { SessionDataKey } from '../agent-api/types';
import type { InternetConnectivityStatus } from '../constants';

export type Action = ActionType<typeof actions>;

export enum LearnCredentialsType {
  DESKTOP_ACCESS = 'DESKTOP_ACCESS',
  EPIC_HYPERDRIVE = 'EPIC_HYPERDRIVE',
}

export type RootState = {
  error: actions.PartialBannerProps | null;
  appAccessContext: AppAccessContext | null;
  pkceData: OidcRequestData | null;
  authnData: Option<AuthnRequestContext> | null;
  // This is how UI component knows to show capture creds form
  uiCaptureCredentials: {
    isPasswordCaptureOnly: boolean;
    usernameHint: string;
    aadUpn: string;
    backendVerificationInProgress: boolean;
    agentVerificationInProgress: boolean;
  } | null;
  agentSaml: string | null;
  networkConnectionStatus: InternetConnectivityStatus;
};

export type EpicDependencies = {
  authenticationStatus$: typeof authenticationStatus$;
  endJourneyAndCloseWindow: typeof endJourneyAndCloseWindow;
  showWindow: typeof showWindow;
  sendAuthnDataToAgent: typeof sendAuthnDataToAgent;
  setAuthenticationStatus: typeof setAuthenticationStatus;
  startApplicationAccess: typeof startApplicationAccess;
  getCredentials: typeof getCredentials;
  processCapturedCredentials: typeof processCapturedCredentials;
  processLoginResult: typeof processLoginResult;
};

export const toPromiseType = async <T>(
  observable: Observable<T>,
): Promise<T> => {
  return observable as unknown as Promise<T>;
};

export interface UiCaptureCredentialsActionData {
  isPasswordCaptureOnly: boolean;
  usernameHint: string;
  aadUpn: string;
  banner?: actions.PartialBannerProps;
}

export interface ProcessCapturedCredentialsActionData {
  codingContext: CodingContext;
  username: string;
  password: string;
  isPasswordCaptureOnly?: boolean;
}

export interface AppAccessContext extends Record<string, unknown> {
  username?: string;
  password?: string;
  domain?: string;
  usernameEditable?: boolean;
  samlData?: string;
  codingContext?: CodingContext;
  isSamlAuthFlow?: boolean;

  // Epic Oauth Workflow: APM-893
  baseUrl?: string;
  fhirServerUrl?: string;
}

export interface SamlData extends Record<string, unknown> {
  spEntityId?: string;
  audience?: string;
  recipient?: string;
  transactionId?: string;
  accepted?: boolean;
  required?: boolean;
}

export interface ReadSessionDataRequest {
  keys: SessionDataKey[];
  codingContext: CodingContext;
  identityToken: string;
}

export interface AuthnRequestContext extends AuthnRequest {
  samlData: string;
}
