// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Toast, ToastType } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import { getHostnameDescription } from '../../utils/utils';

export const NetworkErrorToast = () => {
  const { t } = useTranslation();

  return (
    <Toast
      type={ToastType.WARNING}
      primary={t('error.UXID_NETWORK_ERROR.message')}
      secondary={`${t(
        'error.UXID_NETWORK_ERROR.description',
      )}${getHostnameDescription()}`}
    />
  );
};
