// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Button, Select } from '@imprivata-cloud/components';

import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../../constants';
import { usePersistentSessionState } from '../../hooks';
import { Section } from '../components/Section';

export const AuthenticationStatusSection = () => {
  const [state, setState] = usePersistentSessionState({
    tu__authentication_status: AuthenticationResultStatusCode.SUCCESS,
  });

  return (
    <Section header="Authentication Status">
      <Select
        style={{ width: '280px' }}
        defaultValue={state.tu__authentication_status}
        onChange={statusCode => {
          setState({ tu__authentication_status: statusCode });
        }}
        options={[
          {
            value: AuthenticationReadinessStatusCode.READY,
            label: AuthenticationReadinessStatusCode.READY,
          },
          {
            value: AuthenticationReadinessStatusCode.NOT_READY,
            label: AuthenticationReadinessStatusCode.NOT_READY,
          },
          {
            value: AuthenticationResultStatusCode.SUCCESS,
            label: AuthenticationResultStatusCode.SUCCESS,
          },
          {
            value: AuthenticationResultStatusCode.FAILURE,
            label: AuthenticationResultStatusCode.FAILURE,
          },
        ]}
        majorStyle
      />
      <Button
        size="major"
        onClick={() => {
          window.impr?._config.sendAuthenticationStatus(
            state.tu__authentication_status,
          );
        }}
      >
        Send
      </Button>
    </Section>
  );
};
