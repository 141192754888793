// Copyright 2022, Imprivata, Inc.  All rights reserved.

import translations from './translations/en.json';

export const defaultNS = 'translation' as const;

export const ns = [defaultNS];
export const resources = {
  en: {
    [defaultNS]: translations,
  },
} as const;

export enum ContextNames {}
