// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  InputBox,
  Button,
  ButtonVariant,
  ButtonSize,
  ImprTooltip,
} from '@imprivata-cloud/components';
import { useEffect, useRef } from 'react';
import type { InputRef } from 'antd';
import classes from './UsernamePassword.module.css';

interface FormValues {
  username: string;
  password: string;
}

interface IProps {
  values: FormValues;
  onChange: (values: FormValues) => void;
  onSubmit: () => void;
  onCancel: () => void;
  disabled: boolean;
  title: string;
  info: string;
  userPrincipalName: string;
  usernameLabel: string;
  passwordLabel: string;
  capsLockTooltipLabel: string;
  submitButtonText: string;
  cancelButtonText: string;
  usernameDisabled: boolean;
}

export const truncate30 = (text: string) => {
  return text.slice(0, 13) + '...' + text.slice(-13);
};

const tooltipStyle = {
  maxWidth: 'none',
  width: 'auto',
};

export const UsernamePasswordForm: React.FC<IProps> = ({
  values,
  onChange,
  onSubmit,
  onCancel,
  disabled,
  title,
  info,
  usernameLabel,
  passwordLabel,
  capsLockTooltipLabel,
  submitButtonText,
  cancelButtonText,
  usernameDisabled,
  ...props
}) => {
  const usernameRef = useRef<InputRef>(null);
  const passwordRef = useRef<InputRef>(null);

  useEffect(() => {
    const setFocus = (event?: MouseEvent) => {
      const clickedElement = event?.target as HTMLElement;
      if (
        clickedElement === usernameRef.current?.input ||
        clickedElement === passwordRef.current?.input
      ) {
        return;
      }

      if (!usernameDisabled) {
        usernameRef?.current?.focus();
      } else {
        passwordRef?.current?.focus();
      }
    };

    if (!disabled) {
      setFocus();
    }

    document.body.addEventListener('click', setFocus);
    return () => {
      document.body.removeEventListener('click', setFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernameDisabled]);

  return (
    <div {...props}>
      <div className={classes.container}>
        <div data-testid="form-title" className={classes.title + ' h3-header'}>
          {title}
        </div>
        <div data-testid="form-info" className={classes.info + ' primary-body'}>
          {info}
        </div>
        <label data-testid="form-upn" className={classes.upn + ' primary-body'}>
          {props.userPrincipalName.length > 30 ? (
            <ImprTooltip
              overlayStyle={tooltipStyle}
              title={props.userPrincipalName}
              placement="right"
              data-testid="tooltip-upn"
            >
              {truncate30(props.userPrincipalName)}
            </ImprTooltip>
          ) : (
            props.userPrincipalName
          )}
        </label>
        <div className={classes.usernameContainer}>
          <label
            htmlFor="usernameInput"
            data-testid="username-input-label"
            className="secondary-body"
          >
            {usernameLabel}
          </label>
          <InputBox
            ref={usernameRef}
            type="text"
            autoComplete="off"
            data-testid="username-input"
            placeholder=""
            width="100%"
            size="large"
            id="usernameInput"
            value={values.username}
            onChange={e => {
              onChange({ ...values, username: e.target.value });
            }}
            autoFocus={!usernameDisabled}
            disabled={usernameDisabled}
          />
        </div>
        <div className={classes.passwordContainer}>
          <label
            htmlFor="passwordInput"
            data-testid="password-input-label"
            className="secondary-body"
          >
            {passwordLabel}
          </label>
          <InputBox
            ref={passwordRef}
            type="password"
            placeholder=""
            data-testid={'password-input'}
            width="100%"
            size="large"
            id="passwordInput"
            value={values.password}
            onChange={e => {
              onChange({ ...values, password: e.target.value });
            }}
            autoFocus={usernameDisabled}
            capsLockTooltip={{ label: capsLockTooltipLabel }}
          />
        </div>
        <Button
          onClick={onSubmit}
          className={classes.submitButton}
          disabled={disabled}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MAJOR}
          label={submitButtonText}
          data-testid="submit-button"
          type="primary"
          block={true}
          htmlType="submit"
          id="submitButton"
        />
        <Button
          onClick={onCancel}
          className={classes.cancelButton}
          size={ButtonSize.MAJOR}
          variant={ButtonVariant.TEXT}
          label={cancelButtonText}
          data-testid="cancel-button"
          type="text"
          block={true}
          id="cancelButton"
        />
      </div>
    </div>
  );
};
