// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type Loggable, log as commonLog } from '@imprivata-cloud/common';
import getConfig from '../../appConfigUtils';
import { DISABLE_EA_MOCK } from '../constants';

export const shouldMockEndpointAgent = (): boolean => {
  return (
    // Endpoint Agent API is not defined
    typeof window.impr !== 'object' &&
    // Test Utility is not disabled in localStarage
    localStorage.getItem(DISABLE_EA_MOCK) !== 'true' &&
    // Test Utility is enabled in appConfig
    // TODO: Temporary fix, the value from getConfig is of type boolean, it should be string
    String(getConfig().TEST_UTILITY_ENABLED) === 'true'
  );
};

export const getRequiredParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    contextType: searchParams.get('contextType'),
    resourceType: searchParams.get('resourceType'),
    tenantId: searchParams.get('tenantId'),
  };
};

export const closeWindow = () => {
  window.location.href = `${getConfig().API_URL}/close-agent-route`;
};

export const log = (...args: Array<Loggable>) => {
  commonLog('[TestUtility]', ...args);
};
