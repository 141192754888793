// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod } from '@imprivata-cloud/authn';
import type { TraceContext } from '@imprivata-cloud/common/types';
import { AgentEvent } from '../../constants';
import { tracer } from '../../tracing';

export class ProxReader {
  static type = AuthnMethod.PROX;

  simulateEvent(
    eventType: AgentEvent,
    options: { cardNumber?: string },
  ): [TraceContext, { factorData: { id: string; version: string } }] {
    if (eventType === AgentEvent.FACTOR_DATA_READY) {
      if (!options.cardNumber) {
        throw new Error(
          'No `cardNumber` provided for `authn-data-ready` prox event',
        );
      }

      tracer.startSpan('prox_event');
      const ctx = tracer.getTraceContext();
      tracer.endSpan('prox_event');

      return [
        ctx,
        {
          factorData: { id: options.cardNumber, version: '1.0' },
        },
      ];
    } else {
      throw new Error(`Unsupported event: ${eventType}`);
    }
  }
}
