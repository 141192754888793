// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { filter, switchMap, of, from } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { base64ToJson } from '@imprivata-cloud/data-privacy-js';
import type { Epic } from 'redux-observable';
import type {
  Action,
  RootState,
  EpicDependencies,
  AppAccessContext,
} from '../types';
import { readAppAccessContext, saveAppAccessContext } from '../actions';
import { getAppAccessContext } from '../../agent-api';

export const readAppAccessContextEpic: Epic<
  Action,
  Action,
  RootState,
  Partial<EpicDependencies>
> = (action$, _, _1) => {
  return action$.pipe(
    filter(isActionOf(readAppAccessContext)),
    switchMap(() => {
      return from(getAppAccessContext()).pipe(
        switchMap(appAccessContext => {
          const decoded: AppAccessContext = appAccessContext
            ? base64ToJson(appAccessContext)
            : ({} as AppAccessContext);
          return of(saveAppAccessContext(decoded));
        }),
      );
    }),
  );
};
