// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  AuthnMethod,
  type AuthnModuleProps,
  ErrorCode as AuthnErrorCode,
  EventType,
  type FactorOption,
  RetryableError,
} from '@imprivata-cloud/authn';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import type {
  EventFunction,
  EventPayload,
} from '@imprivata-cloud/authn/lib/types/events';

import type { SubscribeAuthnDataReadyFn } from '@imprivata-cloud/authn/lib/types/app';
import { option } from 'fp-ts';

import type { RootState } from '../store/types';
import {
  endJourneyAndCloseWindow,
  getFactorTypes,
  showWindow,
  subscribeToAuthnDataReady,
} from '../agent-api';
import { ShowWindowOption } from '../agent-api/types';
import { mapErrorToAction } from '../api/hooks';
import { applyInterceptors } from '../api/interceptors';
import { ErrorCode } from '../api/new/types';
import getConfig from '../appConfigUtils';
import { NetworkErrorToast } from '../components';
import {
  AgentEvent,
  AuthenticationReadinessStatusCode,
  InternetConnectivityStatus,
  NETWORK_CONNECTION_STATUS_DEBOUNCE_TIMEOUT_SEC,
} from '../constants';
import { LearnCredentials as LearnCredentialsScreen } from '../containers';
import {
  useBootstrap,
  useDebounce,
  useGetEpicOauthFlowError,
  useRequestConfig,
} from '../hooks';
import {
  clearError,
  resetAuthnDataAction,
  saveAgentSaml,
  setAuthenticationStatusAction,
  startApplicationAccessAction,
} from '../store/actions';
import { tracer } from '../tracing';
import {
  type FactorDataReady,
  type FactorDataReadyEventHandler,
} from '../types';
import { getBuildInfo } from '../utils/build';
import {
  getCredentialsType,
  isDesktopAccess,
  isEpcsOrderSigning,
  isEpicOauthFlow,
  normalizeFactors,
  useNavigatorOnlineStatus,
} from '../utils/utils';
import { Auth } from './auth';
import { debug, log } from '../utils/logging';

let notified = false;

applyInterceptors();

// TODO: VN-20971 Add tests for this component
export const App = (): JSX.Element => {
  if (!notified) {
    const APP_VERSION = getBuildInfo();
    debug('APP VERSION', '[authn]:', APP_VERSION);
    notified = true;
  }

  const [initialFactors, setInitialFactors] = useState<
    AuthnMethod[] | undefined
  >();
  const [offlineFactors, setOfflineFactors] = useState<
    FactorOption[] | undefined
  >();

  const { error, uiCaptureCredentials, networkConnectionStatus } = useSelector(
    (state: RootState) => ({
      error: state.error,
      uiCaptureCredentials: state.uiCaptureCredentials,
      networkConnectionStatus: state.networkConnectionStatus,
    }),
  );

  const config = getConfig();
  const dispatch = useDispatch();

  const store = useStore<RootState>();

  const [isOnline, setIsOnline] = useState<boolean | undefined>(undefined);
  const isNavigatorOnline = useNavigatorOnlineStatus();
  const debouncedNetworkConnectionStatus = useDebounce(
    networkConnectionStatus,
    NETWORK_CONNECTION_STATUS_DEBOUNCE_TIMEOUT_SEC * 1000,
  );

  useEffect(() => {
    log(
      `Network connection status: ${debouncedNetworkConnectionStatus}, isOnline: ${isOnline}, isNavigatorOnline: ${isNavigatorOnline}`,
    );
    if (
      debouncedNetworkConnectionStatus === InternetConnectivityStatus.CONNECTED
    ) {
      if (isOnline === false) {
        // this check added since CEF for some reason still if offline when EA sends CONNECTED status
        isNavigatorOnline && window.location.reload();
      } else {
        setIsOnline(true);
      }
    } else {
      setIsOnline(false);
    }
  }, [debouncedNetworkConnectionStatus, isOnline, isNavigatorOnline]);

  useEffect(() => {
    getFactorTypes()
      .then(f => {
        const factors = normalizeFactors(f);
        debug('getFactorTypes', factors);
        setInitialFactors(factors || []);
      })
      .catch(err => {
        console.error('[AppAccess] Error fetching factors', err);
        setInitialFactors([]);
      });
  }, []);

  const errorBanner = useMemo(
    () =>
      error
        ? {
            ...error,
            onClose: () => {
              dispatch(clearError());
            },
          }
        : undefined,
    [error, dispatch],
  );

  const sendAuthnDataReady = useCallback(
    (
      callback: FactorDataReadyEventHandler,
      eventName: AgentEvent,
      data: FactorDataReady,
    ) => {
      dispatch(saveAgentSaml(data.authnData.samlData));
      callback(eventName, data);
    },
    [dispatch],
  );

  const onAuthnDataReady = useCallback(
    (callback: SubscribeAuthnDataReadyFn) => {
      debug('subscribeToAuthnDataReady');
      const authnData = store.getState().authnData;
      if (authnData && option.isSome(authnData)) {
        debug('sendAuthnDataReady');
        sendAuthnDataReady(callback, AgentEvent.FACTOR_DATA_READY, {
          traceContext: tracer.getTraceContext(),
          authnData: authnData.value,
        });
        dispatch(resetAuthnDataAction());
      }
      subscribeToAuthnDataReady((event, data) => {
        sendAuthnDataReady(callback, event, data);
      });
    },
    [store, dispatch, sendAuthnDataReady],
  );

  const contextResource = useMemo(() => {
    return {
      contextType:
        new URL(window.location.href).searchParams.get('contextType') || '',
      resourceType:
        new URL(window.location.href).searchParams.get('resourceType') || '',
    };
  }, []);

  const requestConfig = useRequestConfig();
  const { ready } = useBootstrap();

  const handleTapOver: EventFunction = useCallback(({ event, data }) => {
    // in case tap-over the window should be shown only if user attention is required or failure occurred
    if (isDesktopAccess()) {
      switch (event) {
        case EventType.SUCCESS:
          if (data && data.nextFactor) {
            showWindow(ShowWindowOption.show);
          }
          break;
        case EventType.NOT_READY:
        case EventType.AUTHENTICATED:
        case EventType.FACTORS:
        case EventType.READY:
        case EventType.INITIALIZED:
          break;
        default: {
          showWindow(ShowWindowOption.show);
        }
      }
    }
  }, []);

  const onEvent: AuthnModuleProps['onEvent'] = useCallback(
    ({ event, data }) => {
      debug('onEvent', { event, data });
      handleTapOver({ event, data } as EventPayload);
      switch (event) {
        case EventType.ERROR:
          // in this case first step is to show banner to user, and if user tries to authn - put app-access into offline-mode (setAuthenticationStatus(offline))
          // do this only for DA
          if (
            isDesktopAccess() &&
            data?.code === AuthnErrorCode.NETWORK_ERROR
          ) {
            log('Network error, setting app-access to offline mode', { data });

            setOfflineFactors([
              { factorType: AuthnMethod.PROX, factorCategory: 'have' },
            ]);

            mapErrorToAction(
              data?.code,
              // we need actual state of store to properly put app access into offline mode
              store.getState().networkConnectionStatus,
              dispatch,
            );
          } else if (isEpcsOrderSigning()) {
            if (
              !(data instanceof RetryableError) ||
              data?.code === AuthnErrorCode.FACTORS_INSUFFICIENT
            ) {
              endJourneyAndCloseWindow(
                tracer.getWorkflowId(),
                tracer.getTraceContext(),
              );
            }
          }
          break;

        case EventType.IMPR_ID_ERROR:
          mapErrorToAction(
            ErrorCode.IID_AUTH_ERROR,
            store.getState().networkConnectionStatus,
            dispatch,
          );
          break;
        case EventType.TERMINATE_SESSION:
          // In case of epcs order signing, the window is always closed
          if (isEpcsOrderSigning()) {
            mapErrorToAction(
              ErrorCode.TERMINATE_BY_TIMEOUT,
              store.getState().networkConnectionStatus,
              dispatch,
            );
          }
          break;
        case EventType.AUTHENTICATED:
          dispatch(
            startApplicationAccessAction.request({
              ...contextResource,
              // TODO: Remove this assertion by fixing type in authn module
              ...data,
            }),
          );
          break;
        case EventType.FACTORS:
          dispatch(
            setAuthenticationStatusAction(
              AuthenticationReadinessStatusCode.READY,
            ),
          );
          break;
        case EventType.NOT_READY:
          dispatch(
            setAuthenticationStatusAction(
              AuthenticationReadinessStatusCode.NOT_READY,
            ),
          );
          break;
      }
    },
    [contextResource, handleTapOver, dispatch, store],
  );

  const { fatalError, banner: oauthBanner } = useGetEpicOauthFlowError();
  const banner = isEpicOauthFlow().failure ? oauthBanner : errorBanner;

  debug(
    '[AppAccess] Error: ',
    { error },
    'Banner: ',
    banner,
    'FatalError: ',
    fatalError,
  );
  if (isEpicOauthFlow().success && !error) {
    debug(
      '[AppAccess] Epic Oauth success and no errors detected, not displaying UI',
    );
    return <></>;
  }

  return uiCaptureCredentials ? (
    <LearnCredentialsScreen credentialType={getCredentialsType()} />
  ) : (
    <div className="app-access-container">
      {(ready && initialFactors && (
        <Auth
          banner={banner}
          error={fatalError}
          factors={offlineFactors}
          deviceFactors={initialFactors}
          requestConfig={requestConfig}
          contextResource={contextResource}
          onEvent={onEvent}
          callbacks={{ subscribeToAuthnDataReady: onAuthnDataReady }}
          hideFactorSwitch={
            // this check is to close factor switcher while offline mode
            // we need to consider to pass offline status to authn-module to avoid this outer checks
            debouncedNetworkConnectionStatus ===
              InternetConnectivityStatus.DISCONNECTED ||
            !config.FACTOR_SWITCH_ENABLED
          }
        />
      )) || <></>}
      {debouncedNetworkConnectionStatus ===
        InternetConnectivityStatus.DISCONNECTED &&
        (<NetworkErrorToast /> || <></>)}
    </div>
  );
};

export default App;
