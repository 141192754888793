// Copyright 2023, Imprivata, Inc.  All rights reserved.

import { filter, switchMap, of, from } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import type { Action, RootState, EpicDependencies } from '../types';
import { readPkceData, savePkceData } from '../actions';
import { getPKCEData } from '../../agent-api';

export const readPkceDataEpic: Epic<
  Action,
  Action,
  RootState,
  Partial<EpicDependencies>
> = (action$, _, _1) => {
  return action$.pipe(
    filter(isActionOf(readPkceData)),
    switchMap(() => {
      return from(getPKCEData()).pipe(
        switchMap(pkceData => {
          return of(savePkceData(pkceData));
        }),
      );
    }),
  );
};
