// Copyright 2023, Imprivata, Inc.  All rights reserved.

import { filter, switchMap, of, from } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { ContextHeader } from '@imprivata-cloud/data-privacy-js';
import {
  base64ToJson,
  createContextFromImprCodingCtxHeader,
} from '@imprivata-cloud/data-privacy-js';
import type { Epic } from 'redux-observable';
import type { Action, RootState, EpicDependencies } from '../types';
import {
  readIdentityTokenDataAction,
  readSessionDataAction,
  startApplicationAccessAction,
} from '../actions';
import {
  getQueryParams,
  isDesktopAccess,
  isEpcsOrderSigning,
} from '../../utils/utils';
import { QueryParamName } from '../../shared/types/common';
import { SessionDataKey } from '../../agent-api/types';
import { getIdentityToken } from '../../agent-api';
import { log } from '../../utils/logging';

export const readIdentityTokenDataEpic: Epic<
  Action,
  Action,
  RootState,
  Partial<EpicDependencies>
> = (action$, _, _1) => {
  return action$.pipe(
    filter(isActionOf(readIdentityTokenDataAction.request)),
    switchMap(() => {
      return from(getIdentityToken()).pipe(
        switchMap(payload => {
          const queryParams = getQueryParams();
          const contextType =
            queryParams.get(QueryParamName.CONTEXT_TYPE) || '';
          const resourceType =
            queryParams.get(QueryParamName.RESOURCE_TYPE) || '';

          log(
            'handle readIdentityToken',
            'identityToken is present:',
            !!payload?.identityToken,
            ', codingContext is present:',
            !!payload?.codingContext,
          );

          // EPCS order signing must not make use of identity token
          if (isEpcsOrderSigning()) {
            return of(readIdentityTokenDataAction.success());
          }

          if (payload?.identityToken && payload?.codingContext) {
            const contextJson = base64ToJson(
              payload?.codingContext,
            ) as ContextHeader;
            log(`start session CipherV${contextJson.version}`);
            const codingContext = createContextFromImprCodingCtxHeader(
              payload?.codingContext,
            );
            const identityToken = payload.identityToken;

            if (isDesktopAccess()) {
              return of(
                readSessionDataAction.request({
                  keys: [SessionDataKey.authnData],
                  codingContext,
                  identityToken,
                }),
              );
            }

            return of(
              startApplicationAccessAction.request({
                contextType,
                resourceType,
                codingContext,
                identityToken,
              }),
            );
          }
          // TODO: VN-18616, VN-18623 Renew identityToken if expired, otherwise request Astra Auth
          return of(readIdentityTokenDataAction.success());
        }),
      );
    }),
  );
};
