// Copyright 2023, Imprivata, Inc.  All rights reserved.

import type { CodingContext } from '@imprivata-cloud/data-privacy-js';
import type {
  ApplicationAccessStartRequest,
  ApplicationAccessStartResponse,
  CredentialsGetResponse,
  VerifyEnrollmentRequest,
  VerifyEnrollmentResponse,
} from '../shared/types/api';
import type {
  CapturedCredentialsProcessRequest,
  CapturedCredentialsProcessResponse,
  LoginResultProcessRequest,
  LoginResultProcessResponse,
} from './types';
import getConfigs from '../appConfigUtils';
import { appAccessService } from './endpoints';
import { client } from './client';
import { Headers } from './constants';

const { API_URL } = getConfigs();

export async function startApplicationAccess(
  userSessionRequest: ApplicationAccessStartRequest,
  codingContext?: CodingContext,
  identityToken?: string,
): Promise<ApplicationAccessStartResponse> {
  const url = `${API_URL}${appAccessService.START_APPLICATION_ACCESS}`;
  let axiosConfig = {};
  if (codingContext && identityToken) {
    axiosConfig = authorizationHeaders(identityToken, codingContext);
  }

  // TODO [APM-893]: Add codingContext to localStorage

  return client.post(url, userSessionRequest, axiosConfig);
}

export async function verifyEnrollment(
  request: VerifyEnrollmentRequest,
  identityToken: string,
  codingContext: CodingContext,
): Promise<VerifyEnrollmentResponse> {
  const url = `${API_URL}${appAccessService.VERIFY_ENROLLMENT}`;
  const authHeaders = authorizationHeaders(identityToken, codingContext);
  return client.post(url, request, authHeaders);
}

export async function getCredentials(): Promise<CredentialsGetResponse> {
  const url = `${API_URL}${appAccessService.GET_CREDENTIALS}`;

  // noinspection UnnecessaryLocalVariableJS
  const axiosResult: CredentialsGetResponse = await client.post(url);
  return axiosResult;
}

export async function processCapturedCredentials(
  request: CapturedCredentialsProcessRequest,
): Promise<CapturedCredentialsProcessResponse> {
  const url = `${API_URL}${appAccessService.PROCESS_CAPTURED_CREDENTIALS}`;

  // noinspection UnnecessaryLocalVariableJS
  const axiosResult: CapturedCredentialsProcessResponse = await client.post(
    url,
    request,
  );
  return axiosResult;
}

export async function processLoginResult(
  request: LoginResultProcessRequest,
): Promise<LoginResultProcessResponse> {
  const url = `${API_URL}${appAccessService.PROCESS_LOGIN_RESULT}`;

  // noinspection UnnecessaryLocalVariableJS
  const axiosResult: LoginResultProcessResponse = await client.post(
    url,
    request,
  );
  return axiosResult;
}

const authorizationHeaders = (
  identityToken: string,
  codingContext: CodingContext,
) => ({
  headers: {
    [Headers.ImprCodingCtx]: codingContext.buildImprCodingCtxHeader(),
    [Headers.ImprAuthorization]: `Bearer ${identityToken}`,
  },
});
