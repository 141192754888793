// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { filter, switchMap, of, map, EMPTY, concat } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import type { EpicDependencies, Action, RootState } from '../types';
import type { endJourneyAndCloseWindow } from '../../agent-api';
import {
  subscribeToAuthResponseAction,
  processLoginResultAction,
  errorAction,
  readPkceData,
} from '../actions';
import { tracer } from '../../tracing';
import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../constants';
import { ShowWindowOption } from '../../agent-api/types';
import {
  isDesktopAccess,
  isEpcsOrderSigning,
  isSharedWorkstation,
} from '../../utils/utils';
import { log } from '../../utils/logging';

export function authenticationEndJourney(
  endJourneyAndCloseWindowFunc: typeof endJourneyAndCloseWindow,
) {
  endJourneyAndCloseWindowFunc(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
  );
  tracer.endAllSpans();
  tracer.forceFlush();
}

export const subscribeToAuthResponseEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<
    EpicDependencies,
    'authenticationStatus$' | 'endJourneyAndCloseWindow' | 'showWindow'
  >
> = (action$, state$, dependencies) => {
  const { authenticationStatus$, endJourneyAndCloseWindow, showWindow } =
    dependencies;

  return action$.pipe(
    filter(isActionOf(subscribeToAuthResponseAction)),
    switchMap(() => authenticationStatus$),
    switchMap(args =>
      of(args).pipe(
        // eslint-disable-next-line
        // @ts-ignore
        map(([_, statusData]) => {
          tracer.startSpan('authentication_status_event', {
            statusData,
          });
          return statusData.statusCode;
        }),
        switchMap(statusCode => {
          const isSamlAuthentication =
            state$.value.appAccessContext?.isSamlAuthFlow;
          const appAccessContext = state$.value.appAccessContext;
          switch (statusCode) {
            case AuthenticationResultStatusCode.FAILURE: {
              showWindow(ShowWindowOption.show);
              log('isEpcsOrderSigning', isEpcsOrderSigning());
              if (isEpcsOrderSigning()) {
                authenticationEndJourney(endJourneyAndCloseWindow);
              }
              log('isSamlAuthentication', isSamlAuthentication);
              // this conditional should be removed in scope of VN-19294
              if (!isSamlAuthentication) {
                return concat(
                  isDesktopAccess() ? of(readPkceData()) : EMPTY,
                  of(processLoginResultAction.request(statusCode)),
                );
              }
              return of(errorAction());
            }
            case AuthenticationResultStatusCode.SUCCESS: {
              // this conditional should be removed in scope of VN-19294
              if (
                !isSamlAuthentication &&
                // we don't want this during shared WS flow
                !isSharedWorkstation(appAccessContext)
              ) {
                return of(processLoginResultAction.request(statusCode));
              }
              authenticationEndJourney(endJourneyAndCloseWindow);
              break;
            }

            case AuthenticationReadinessStatusCode.READY:
              showWindow(ShowWindowOption.show);
              break;
            case AuthenticationReadinessStatusCode.NOT_READY:
              showWindow(ShowWindowOption.hide);
              break;
            case AuthenticationReadinessStatusCode.OFFLINE:
              log('app access in offline mode');
              break;
            default:
              log('Unknown status code', statusCode);
          }
          tracer.endSpan('authentication_status_event');

          return EMPTY;
        }),
      ),
    ),
  );
};

export const agentEpic = combineEpics(subscribeToAuthResponseEpic);
