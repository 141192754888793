// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useState } from 'react';
import { Tooltip } from 'antd';
import { InputBox } from '@imprivata-cloud/components';

import { base64ToString, jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import type { SessionData } from '../../../agent-api/types';
import { usePersistentSessionState } from '../../hooks';
import { Section } from '../components/Section';

export const SessionDataSection = () => {
  const [state, setState] = usePersistentSessionState({
    session_data: '[]',
  });
  const [encodingError, setEncodingError] = useState(false);

  return (
    <Section header="SessionData">
      <InputBox
        type="text"
        size="small"
        placeholder="Session Data"
        status={encodingError ? 'error' : ''}
        style={{ width: '280px' }}
        defaultValue={base64ToString(state.session_data)}
        onChange={event => {
          try {
            const encodedSessionData = (
              JSON.parse(event.target.value) as SessionData
            ).flatMap(value =>
              Object.entries(value).map(([key, value]) => {
                return { [key]: jsonToBase64(value) };
              }),
            );
            setState({
              session_data: jsonToBase64(encodedSessionData),
            });
            setEncodingError(false);
          } catch {
            setEncodingError(true);
          }
        }}
      />

      {encodingError && (
        <Tooltip title="String is not in correct JSON format">
          <span>(!)</span>
        </Tooltip>
      )}
    </Section>
  );
};
