import { Space } from 'antd';
import { Button, InputBox } from '@imprivata-cloud/components';

import { Section } from '../components/Section';
import { usePersistentState } from '../../hooks';
import { getRequiredParams } from '../../utils';

export const UiControl = () => {
  const searchParams = getRequiredParams();
  const [paramsValues, setParamsValues] = usePersistentState({
    tu__context_type: searchParams.contextType || 'clinical-app-login',
    tu__resource_type: searchParams.resourceType || 'epic-hyperdrive',
    tu__tenant_id: searchParams.tenantId || '',
  });

  const enabled = !!Object.values(paramsValues).reduce(
    (acc, value) => acc && !!value,
    true,
  );

  return (
    <Section
      header="Control AppAccess UI"
      footer={
        <Space direction="horizontal">
          <Button
            data-testid="tu--open-ui-button"
            variant="primary"
            onClick={() => {
              window.impr?._config.openUi({
                contextType: paramsValues.tu__context_type,
                resourceType: paramsValues.tu__resource_type,
                tenantId: paramsValues.tu__tenant_id,
              });
            }}
            disabled={!enabled}
          >
            Open UI
          </Button>
          <Button
            disabled={!enabled}
            data-testid="tu--close-ui-button"
            onClick={() => {
              window.impr?._config.simulateCloseWindow();
            }}
          >
            Close UI
          </Button>
        </Space>
      }
      verticalContext
    >
      <Space direction="horizontal">
        <InputBox
          placeholder="Context Type"
          size="small"
          value={paramsValues.tu__context_type}
          onChange={e => {
            setParamsValues({
              ...paramsValues,
              tu__context_type: e.target.value,
            });
          }}
          width="120"
          type="text"
          data-testid="tu--context-type-input"
          style={{ ...((!enabled && { border: '1px solid red' }) || {}) }}
        />
        <InputBox
          placeholder="Resource Type"
          value={paramsValues.tu__resource_type}
          onChange={e => {
            setParamsValues({
              ...paramsValues,
              tu__resource_type: e.target.value,
            });
          }}
          width="120"
          type="text"
          size="small"
          data-testid="tu--resource-type-input"
          style={{ ...((!enabled && { border: '1px solid red' }) || {}) }}
        />
      </Space>
      <InputBox
        placeholder="Tenant ID"
        size="small"
        value={paramsValues.tu__tenant_id}
        onChange={e => {
          setParamsValues({
            ...paramsValues,
            tu__tenant_id: e.target.value,
          });
        }}
        type="text"
        data-testid="tu--tenant-id-input"
        style={{ ...((!enabled && { border: '1px solid red' }) || {}) }}
      />
    </Section>
  );
};
