// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod } from '@imprivata-cloud/authn';
import type { TraceContext } from '@imprivata-cloud/common/types';
import { Observable, share } from 'rxjs';
import type { OidcRequestData } from '@imprivata-cloud/authn/lib/types/api';
import type {
  AppAuthenticationCanceledEventHandler,
  AppAuthenticationStatusEventHandler,
  AuthenticationStatusCode,
  CloseWindowEventHandler,
  FactorChangedEventHandler,
  FactorDataReadyEventHandler,
  InternetConnectivityStatusHandler,
  StatusData,
} from '../types';
import type {
  ComponentData,
  ComponentId,
  IdentityTokenData,
  SessionData,
  SessionDataKey,
  ShowWindowOption,
  SystemDataProperty,
  WindowStyle,
} from './types';
import { SpanNames, TraceContexts, tracer } from '../tracing';
import { getWindowStyle, isForeground } from '../utils/utils';
import { AgentEvent } from '../constants';
import { log } from '../utils/logging';

export type FactorFilter = { factorType: AuthnMethod[] } | null;

export function subscribeToAuthnDataReady(
  callback: FactorDataReadyEventHandler,
) {
  subscribeToAgentEvent(
    AgentEvent.FACTOR_DATA_READY,
    { factorType: [AuthnMethod.PROX] },
    // eslint-disable-next-line
    // @ts-ignore
    (eventName: AgentEvent, data: object) => {
      tracer.startSpanFromContext(
        SpanNames.FACTOR_DATA_READY,
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        data.traceContext,
        {},
        TraceContexts.AUTHN_DATA_READY,
      );
      if (isForeground()) {
        log('handled event', AgentEvent.FACTOR_DATA_READY);
        // @ts-expect-error - ...
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        callback(eventName, data);
      } else {
        log('skipped event', AgentEvent.FACTOR_DATA_READY);
      }
    },
  );
}

export function subscribeToFactorChanged(callback: FactorChangedEventHandler) {
  subscribeToAgentEvent(
    AgentEvent.FACTOR_CHANGED,
    { factorType: [AuthnMethod.PROX] },
    (...args: Parameters<FactorChangedEventHandler>) => {
      log('subscribeToFactorChanged', { args });
      callback(...args);
    },
  );
}

export function subscribeToAppAuthenticationStatus(
  callback: AppAuthenticationStatusEventHandler,
) {
  subscribeToAgentEvent(
    AgentEvent.APP_AUTHENTICATION_STATUS,
    null,
    // eslint-disable-next-line
    // @ts-ignore
    (...args) => {
      log('handled event', AgentEvent.APP_AUTHENTICATION_STATUS, args);
      // @ts-expect-error - ...
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      callback(...args);
    },
  );
}

export function subscribeToCloseWindow(callback: CloseWindowEventHandler) {
  subscribeToAgentEvent(AgentEvent.CLOSE_WINDOW, null, callback);
}

export function subscribeToInternetConnectivityStatus(
  callback: InternetConnectivityStatusHandler,
) {
  subscribeToAgentEvent(
    AgentEvent.NETWORK_CONNECTIVITY_CHANGED,
    null,
    // eslint-disable-next-line
    // @ts-ignore
    (...args) => {
      log(`handled event`, AgentEvent.NETWORK_CONNECTIVITY_CHANGED, args);
      // @ts-expect-error - ...
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      callback(...args);
    },
  );
}

export async function getAppAccessContext(): Promise<string> {
  return new Promise<string>(resolve => {
    tracer.startSpan(SpanNames.GET_APP_ACCESS_CONTEXT);
    log('call getAppAccessContext');
    window.impr?.authn.getAppAccessContext(
      tracer.getWorkflowId(),
      tracer.getTraceContext(),
      (appAccessContext: string) => {
        tracer.endSpan(SpanNames.GET_APP_ACCESS_CONTEXT);
        log('execute callback for getAppAccessContext');
        resolve(appAccessContext);
      },
    );
  });
}

export const getSystemDataProperty = (property: SystemDataProperty): string => {
  if (!window.impr?.agent.systemData) {
    return '';
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return window.impr && JSON.parse(window.impr?.agent.systemData)[property];
};

export const authenticationStatus$ = new Observable<[TraceContext, StatusData]>(
  observer => {
    const callback: AppAuthenticationStatusEventHandler = (_, data) => {
      observer.next([data.traceContext, data.statusData]);
    };
    setWindowStyle(getWindowStyle());
    subscribeToAgentEvent(AgentEvent.APP_AUTHENTICATION_STATUS, null, callback);
  },
).pipe(share());

export function endJourneyAndCloseWindow(
  journeyId: string,
  traceContext: TraceContext,
) {
  tracer.startSpan(SpanNames.END_JOURNEY_AND_CLOSE_WINDOW);
  log('call endJourneyAndCloseWindow');
  window.impr?.authn.endJourneyAndCloseWindow(journeyId, traceContext);
  tracer.endSpan(SpanNames.END_JOURNEY_AND_CLOSE_WINDOW);
}

/**
 * Sends authentication-related data back to the Endpoint Agent.
 *
 * Important note: do NOT change the order of passing the OIDC auth code and the credentials. The
 * Endpoint Agent relies on the calling order of these Web APIs.
 *
 * @param authnData contains credentials and/or SAML assertion
 * @param oidcAuthCode contains the OIDC authorization code (optional)
 */
export function sendAuthnDataToAgent(
  authnData: CredentialData,
  oidcAuthCode?: string,
) {
  if (oidcAuthCode) {
    passOidcAuthCodeToAgent(oidcAuthCode);
  }
  tracer.startSpan(SpanNames.SUBMIT_CREDENTIALS);
  log('call submitCredentials2');
  window.impr?.authn.submitCredentials2(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    authnData,
  );
  tracer.endSpan(SpanNames.SUBMIT_CREDENTIALS);
}

function passOidcAuthCodeToAgent(oidcAuthCode: string): void {
  tracer.startSpan(SpanNames.SET_OIDC_AUTH_CODE);
  log('call setAuthZCode');
  window.impr?.authn.setAuthZCode(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    oidcAuthCode,
  );
  tracer.endSpan(SpanNames.SET_OIDC_AUTH_CODE);
}

export async function getFactorTypes() {
  return new Promise<AuthnMethod[]>(resolve => {
    tracer.startSpan(SpanNames.GET_DEVICE_FACTORS);
    log('pass callback to getDeviceFactorTypes');
    window.impr?.authn.getDeviceFactorTypes(
      tracer.getTraceContext(),
      (agentFactors: AuthnMethod[]) => {
        tracer.endSpan(SpanNames.GET_DEVICE_FACTORS, { agentFactors });
        log('execute callback for getDeviceFactorTypes', agentFactors);
        resolve(agentFactors);
      },
    );
  });
}

export function setAuthenticationStatus(statusCode: AuthenticationStatusCode) {
  tracer.startSpan(SpanNames.SET_AUTHENTICATION_STATUS, { statusCode });
  log('call setAuthenticationStatus', statusCode);
  window.impr?.authn.setAuthenticationStatus(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    statusCode,
  );
  tracer.endSpan(SpanNames.SET_AUTHENTICATION_STATUS);
}

/**
 * Shows or hides the window and sets the window style.
 * @param showWindowOption - The option to show or hide the window.
 */
export function showWindow(showWindowOption: ShowWindowOption) {
  tracer.startSpan(SpanNames.SHOW_WINDOW, { showWindowOption });
  log('call showWindow2', showWindowOption);
  window.impr?.window.showWindow2(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    [showWindowOption],
  );
  tracer.endSpan(SpanNames.SHOW_WINDOW);
  // explicitly end all other spans as soon as the window is shown
  tracer.endAllSpans();
}

/**
 * Sets the window style.
 * @param windowStyle - An array of window styles to set.
 */
export function setWindowStyle(windowStyle: WindowStyle[]): void {
  tracer.startSpan(SpanNames.SET_WINDOW_STYLE, { windowStyle });
  log('call setWindowStyle', windowStyle);
  window.impr?.window.setWindowStyle(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    windowStyle,
  );
  tracer.endSpan(SpanNames.SET_WINDOW_STYLE);
}

type SubscriptionArguments =
  | [
      AgentEvent.APP_AUTHENTICATION_STATUS,
      FactorFilter,
      AppAuthenticationStatusEventHandler,
    ]
  | [
      AgentEvent.APP_AUTHENTICATION_CANCELED,
      FactorFilter,
      AppAuthenticationCanceledEventHandler,
    ]
  | [AgentEvent.FACTOR_DATA_READY, FactorFilter, FactorDataReadyEventHandler]
  | [AgentEvent.CLOSE_WINDOW, FactorFilter, CloseWindowEventHandler]
  | [AgentEvent.FACTOR_CHANGED, FactorFilter, FactorChangedEventHandler]
  | [
      AgentEvent.NETWORK_CONNECTIVITY_CHANGED,
      null,
      InternetConnectivityStatusHandler,
    ];

export function subscribeToAgentEvent(
  ...[event, filter, callback]: SubscriptionArguments
): void {
  log('subscribe to event', event);
  window.impr?.event.on2(
    [event],
    tracer.getWorkflowId(),
    filter,
    // eslint-disable-next-line
    // @ts-ignore
    (eventName: AgentEvent, data: object) => {
      log('execute callback for event', 'event:', eventName, ', data:', data);
      // WorkflowId is not part of Endpoint Agent TraceContext. Some of the auth functions require it.
      // Because of this explicitly adds workflowId to the traceContext
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      // @ts-expect-error - ...
      callback(eventName, {
        ...data,
        traceContext: {
          // @ts-expect-error - ...
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          spanContext: data?.traceContext.spanContext,
          workflowId: tracer.getWorkflowId(),
        },
      });
    },
  );
}

/**
 * Retrieves PKCE data from the Endpoint Agent.
 * @returns Promise<OidcRequestData> - the Oidc Request Data
 */
export async function getPKCEData(): Promise<OidcRequestData> {
  return new Promise<OidcRequestData>(resolve => {
    tracer.startSpan(SpanNames.GET_PKCE_DATA);
    log('call getPKCEData');
    window.impr?.authn.getPKCEData(
      tracer.getWorkflowId(),
      tracer.getTraceContext(),
      (codeChallenge: string, codeChallengeMethod: string) => {
        tracer.endSpan(SpanNames.GET_PKCE_DATA);
        log('execute callback for getPKCEData');
        resolve({ codeChallenge, codeChallengeMethod });
      },
    );
  });
}

/**
 * Retrieves Identity token and Coding context from the Endpoint Agent.
 * @returns Promise<IdentityTokenData> - the Identity Token Data
 */
export async function getIdentityToken(): Promise<IdentityTokenData> {
  return new Promise<IdentityTokenData>(resolve => {
    tracer.startSpan(SpanNames.GET_IDENTITY_TOKEN);
    log('call getIdentityToken');
    window.impr?.authn?.getIdentityToken(
      tracer.getWorkflowId(),
      tracer.getTraceContext(),
      (identityToken: string, codingContext: string) => {
        tracer.endSpan(SpanNames.GET_IDENTITY_TOKEN);
        log('execute callback for getIdentityToken');
        resolve({ identityToken, codingContext });
      },
    );
  });
}

/**
 * Retrieves information of Component from the Endpoint Agent.
 * @param componentId - The id of component to retrieve.
 * @returns Promise<ComponentData>
 */
export async function getComponentData(
  componentId: ComponentId,
): Promise<ComponentData> {
  return new Promise<ComponentData>(resolve => {
    tracer.startSpan(SpanNames.GET_COMPONENT_INFORMATION, {
      componentId,
    });
    window.impr?.update_manager?.getComponentInformation(
      tracer.getWorkflowId(),
      tracer.getTraceContext(),
      componentId,
      data => {
        tracer.endSpan(SpanNames.GET_COMPONENT_INFORMATION);
        log(
          'execute callback for getComponentInformation',
          'componentId:',
          componentId,
          ', data:',
          data,
        );
        resolve(data!);
      },
    );
  });
}

/**
 * Retrieves Session Data from the Endpoint Agent.
 * @param keys - The keys of the session data to retrieve.
 * @returns Promise<SessionData> - the Session Data
 */
export async function getSessionData(
  keys: SessionDataKey[],
): Promise<SessionData> {
  return new Promise<SessionData>(resolve => {
    tracer.startSpan(SpanNames.GET_SESSION_DATA, {
      keys,
    });
    log('call impr.session.getData', keys);
    window.impr?.session?.getData(
      tracer.getWorkflowId(),
      tracer.getTraceContext(),
      keys,
      (sessionData: SessionData) => {
        tracer.endSpan(SpanNames.GET_SESSION_DATA);
        log('execute callback for impr.session.getData', keys);
        resolve(sessionData);
      },
    );
  });
}

/**
 * Saves sessionData in the current user session.
 *
 * @param sessionData the session data
 */
export function setSessionData(sessionData: SessionData): void {
  tracer.startSpan(SpanNames.SET_SESSION_DATA);
  log('call impr.session.setData');
  window.impr?.session?.setData(
    tracer.getWorkflowId(),
    tracer.getTraceContext(),
    sessionData,
  );
  tracer.endSpan(SpanNames.SET_SESSION_DATA);
}

/**
 * Retrieves Session Data from the Endpoint Agent and clears the data.
 * @param keys - The keys of the session data to retrieve.
 * @returns Promise<SessionData> - the Session Data
 */
export async function getAndClearSessionData(
  keys: SessionDataKey[],
): Promise<SessionData> {
  return getSessionData(keys).then(result => {
    log('call impr.session.setData for clearing session data');
    // TODO VN-20977: change to setSessionData(null) or setSessionData([])
    setSessionData(keys.map(key => ({ [key]: '' })));
    return result;
  });
}
