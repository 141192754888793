// Copyright 2023, Imprivata, Inc.  All rights reserved.

import type { Secret } from '@imprivata-cloud/data-privacy-js';

export interface CredentialsGetResponse {
  nextAction: CredentialsGetResponseNextAction;
  usernameHint?: string;
  aadUpn?: string;
  credentialData?: Secret;
  epicAuthorizationUrl?: string;
}

export enum CredentialsGetResponseNextAction {
  CAPTURE_CREDENTIALS = 'capture-credentials',
  CAPTURE_PASSWORD = 'capture-password',
  VERIFY_CREDENTIALS = 'verify-credentials',
  ENROLL_USER = 'enroll-user',
}
