// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { EMPTY, filter, from, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';

import type { Action, EpicDependencies, RootState } from '../types';
import { readComponentData } from '../actions';
import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../constants';
import { log } from '../../utils/logging';
import { getComponentData } from '../../agent-api';

export const readComponentDataEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'setAuthenticationStatus'>
> = (action$, _, dependencies) => {
  const { setAuthenticationStatus } = dependencies;

  return action$.pipe(
    filter(isActionOf(readComponentData)),
    switchMap(({ payload }) => {
      return from(getComponentData(payload)).pipe(
        switchMap(componentData => {
          log(
            `read component (${payload}), data: ${JSON.stringify(componentData)}`,
          );
          if (!componentData?.installed) {
            setAuthenticationStatus(AuthenticationReadinessStatusCode.READY);
          } else {
            setAuthenticationStatus(AuthenticationResultStatusCode.FAILURE);
          }
          return EMPTY;
        }),
      );
    }),
  );
};
