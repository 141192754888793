/**
 * NOTE: This module is temporary. It is used ONLY to FIX the render issues around this application
 */

import React, { useMemo } from 'react';
import { AuthnModule } from '@imprivata-cloud/authn';
import type { AuthnModuleProps, Callbacks } from '@imprivata-cloud/authn';

import { compareArrays, compareObjects } from '@imprivata-cloud/common';

import { getFactorTypes, subscribeToFactorChanged } from '../../agent-api';
import { tracer } from '../../tracing';

const AuthComponent = (
  props: Omit<AuthnModuleProps, 'tenantId' | 'tracing'>,
) => {
  const tenantId = new URL(window.location.href).searchParams.get('tenantId');

  const authnModuleDeps: Callbacks = {
    getFactorTypes,
    subscribeToFactorChanged,
    subscribeToAuthnDataReady: props.callbacks?.subscribeToAuthnDataReady,
  };

  const workflowId = useMemo(() => tracer.getWorkflowId(), []);

  if (!tenantId) {
    return <></>;
  }

  return (
    (
      <AuthnModule
        {...props}
        tenantId={tenantId}
        callbacks={authnModuleDeps}
        tracing={{
          workflowId,
          tracer,
        }}
      />
    ) || <></>
  );
};

// TODO: Remove one of the below. As a matter of fact, we are memoizing at the
// AuthnModule level, so we don't need to memoize here. Need to verify.
// export const Auth = React.memo(AuthComponent, comparePropsEqual);

export const Auth = React.memo(AuthComponent, (prev, next) => {
  const prevError = prev.banner;
  const nextError = next.banner;

  const prevFactors = prev.deviceFactors;
  const nextFactors = next.deviceFactors;

  const prevOfflineFactors = prev.factors;
  const nextOfflineFactors = next.factors;

  const prevHideFactorSwitch = prev.hideFactorSwitch;
  const nextHideFactorSwitch = next.hideFactorSwitch;

  const prevRequestConfig = prev.requestConfig;
  const nextRequestConfig = next.requestConfig;

  return (
    `${prevError?.primary || ''}${prevError?.secondary}` ===
      `${nextError?.primary || ''}${nextError?.secondary}` &&
    prevHideFactorSwitch === nextHideFactorSwitch &&
    compareObjects(prevRequestConfig, nextRequestConfig) &&
    compareArrays(prevFactors, nextFactors) &&
    compareArrays(prevOfflineFactors, nextOfflineFactors)
  );
});
