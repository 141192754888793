import { Divider, type DividerProps } from 'antd';

export const Title = ({ children, ...props }: DividerProps) => {
  return (
    <Divider
      type="horizontal"
      orientation="left"
      {...props}
      style={{ borderColor: 'var(--swagger)', ...(props.style || {}) }}
    >
      {children}
    </Divider>
  );
};
