// Copyright 2023, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common';
import type { InternetConnectivityStatus } from '../constants';

export enum ShowWindowOption {
  hide = 'hide',
  show = 'show',
  showmaximized = 'showmaximized',
}

export enum WindowStyle {
  border = 'border', // if used, adds a border to the window
  sysmenu = 'sysmenu', // if used, adds a system menu and X button
}

export interface IdentityTokenData {
  identityToken?: string;
  codingContext?: string;
}

export enum SystemDataProperty {
  hostname = 'computerName',
}

export enum SessionDataKey {
  authnData = 'authnData',
}

export type SessionData = Array<Record<string, string>>;

export enum ComponentId {
  DESKTOP_ACCESS = 'FA862B77-5CFA-4EA9-A95A-9E07D21ED468',
}

export interface ComponentData {
  installed: boolean;
  version?: string;
  locale?: string;
}

export interface InternetConnectivityStatusCallback {
  traceContext: TraceContext;
  event: InternetConnectivityStatus;
}
