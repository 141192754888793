// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { EventsType } from 'react-idle-timer';

export const LEARN_CREDENTIALS_DISPLAY_TIMEOUT_SEC = 10;
export const NETWORK_CONNECTION_STATUS_DEBOUNCE_TIMEOUT_SEC = 1;
export const PAGE_RELOAD_TIMEOUT_AFTER_GET_ONLINE_SEC = 3;
export const USER_ACTIVITY_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
];

export enum AuthenticationReadinessStatusCode {
  READY = 'ready',
  NOT_READY = 'not-ready',
  OFFLINE = 'offline',
}

export enum AuthenticationResultStatusCode {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum InternetConnectivityStatus {
  CONNECTED = 'internet-connected',
  DISCONNECTED = 'internet-disconnected',
}

export enum AgentEvent {
  FACTOR_DATA_READY = 'factor-data-ready',
  FACTOR_CHANGED = 'factor-changed',
  ERROR = 'error',
  CLOSE_WINDOW = 'close-window',
  APP_AUTHENTICATION_STATUS = 'app-authentication-status',
  APP_AUTHENTICATION_CANCELED = 'app-authentication-canceled',
  NETWORK_CONNECTIVITY_CHANGED = 'network-connectivity-changed',
}
