// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod } from '@imprivata-cloud/authn';
import { v4 as uuid } from 'uuid';
import { AgentEvent } from '../constants';
import { EndpointAgentMock } from './EndpointAgentMock';
import { attachTestUtility } from './test-utility';
import { log, shouldMockEndpointAgent } from './utils';

export const mockWorkflowId = uuid() + 'mock';

export function attachAgentMock(): void {
  const agentMock = new EndpointAgentMock();

  log('Attaching agent mock.');

  // @ts-expect-error - ...
  window.impr = agentMock;
  // eslint-disable-next-line
  // @ts-ignore
  window.tapProximityCard = () => {
    log('Tapping proximity card.');
    agentMock._config.simulateDeviceInteraction(
      AuthnMethod.PROX,
      AgentEvent.FACTOR_DATA_READY,
      { cardNumber: '000001' },
    );
  };

  // eslint-disable-next-line
  // @ts-ignore
  window.tapUnenrolledCard = () => {
    agentMock._config.simulateDeviceInteraction(
      AuthnMethod.PROX,
      AgentEvent.FACTOR_DATA_READY,
      { cardNumber: '999999' },
    );
  };
  // eslint-disable-next-line
  // @ts-ignore
  window.connectReader = () => {
    agentMock._config.simulateConnectDevice(AuthnMethod.PROX);
  };
  // eslint-disable-next-line
  // @ts-ignore
  window.disconnectReader = () => {
    agentMock._config.simulateDisconnectDevice(AuthnMethod.PROX);
  };

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('journeyId')) {
    const storedJourneyId = sessionStorage.getItem('tu__journey_id');
    if (storedJourneyId !== queryParams.get('journeyId')) {
      queryParams.set('journeyId', mockWorkflowId);
      window.location.search = queryParams.toString();
      sessionStorage.setItem('tu__journey_id', mockWorkflowId);
    } else {
      sessionStorage.removeItem('tu__journey_id');
    }
  }
  agentMock._config.simulateConnectDevice(AuthnMethod.PROX);

  attachTestUtility();
}

export const attachAgentMockIfNeeded = () => {
  if (shouldMockEndpointAgent()) {
    attachAgentMock();
  }
};
