// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useState } from 'react';
import { ContextType, ResourceType } from '@imprivata-cloud/authn';
import { base64ToJson } from '@imprivata-cloud/data-privacy-js';

import type { SessionData } from '../agent-api/types';
import type { AppAccessContext, SamlData } from '../store/types';
import { SystemDataProperty, WindowStyle } from '../agent-api/types';
import { LearnCredentialsType } from '../store/types';
import { getSystemDataProperty } from '../agent-api';
import i18n from '../i18n';
import { QueryParamName } from '../shared/types/common';

export const getQueryParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search);
};

export const getWindowStyle = (): WindowStyle[] => {
  if (isDesktopAccess()) {
    // do not return any window styling for desktop access
    return [];
  }
  // default window styling shows a border and a system menu
  return [WindowStyle.border, WindowStyle.sysmenu];
};

export const getContextType = (): string => {
  const params = getQueryParams();
  return params.get(QueryParamName.CONTEXT_TYPE) || '';
};

export function isDesktopAccess(): boolean {
  const params = getQueryParams();
  return (
    params.get(QueryParamName.CONTEXT_TYPE) === ContextType.DESKTOP_ACCESS &&
    params.get(QueryParamName.RESOURCE_TYPE) === ResourceType.ENDPOINT
  );
}

export function isEpicOauthFlow(): { success: boolean; failure: boolean } {
  const path = window.location.pathname;
  return {
    success: path.includes('enroll-success'),
    failure: path.includes('enroll-failure'),
  };
}

export function isEpicAccess(): boolean {
  const params = getQueryParams();
  return (
    params.get(QueryParamName.CONTEXT_TYPE) === ContextType.CLINICAL_APP_LOGIN
  );
}

export function isEpcsOrderSigning(): boolean {
  const params = getQueryParams();
  return (
    params.get(QueryParamName.CONTEXT_TYPE) === ContextType.EPCS_ORDER_SIGNING
  );
}

export function isSharedWorkstation(
  appAccessContext: AppAccessContext | null,
): boolean {
  return !!(
    isDesktopAccess() &&
    appAccessContext?.username &&
    appAccessContext?.password
  );
}

export const isForeground = (): boolean => {
  return isDesktopAccess() || (document.hasFocus() && !document.hidden);
};

/**
 * If the hostname is available from Agent properties, return it in a description.
 * Otherwise, return an empty string.
 *
 * The hostname is modified to include zero-width spaces after each underscore or dot to ensure
 * proper line breaking if the hostname is too long.
 *
 */
export function getHostnameDescription(): string {
  const hostname = getSystemDataProperty(SystemDataProperty.hostname);
  if (!hostname) {
    return '';
  }
  return (
    `\n${i18n.t('error.UXID_NETWORK_ERROR.hostname')}: ` +
    hostname.replace(
      /[_.]/g,
      '$&\u200B', // add zero-width space after each underscore or dot to ensure
      // hostname is broken up into multiple lines if it's too long
    )
  );
}

export const sessionDataToString = (sessionData?: SessionData): string => {
  // replace data by asterisks is used to avoid logging user sensitive data
  const convertedSessionData = sessionData
    ?.map(value =>
      Object.entries(value).map(
        ([key, value]) =>
          `{ "${key}": "${JSON.stringify(value).replace(/./g, '*')}" }`,
      ),
    )
    ?.join(', ');
  return `[${convertedSessionData ?? ''}]`;
};

export const useNavigatorOnlineStatus = () => {
  const [online, setOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }
    function handleOffline() {
      setOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return online;
};

export const getCredentialsType = () => {
  return isDesktopAccess()
    ? LearnCredentialsType.DESKTOP_ACCESS
    : LearnCredentialsType.EPIC_HYPERDRIVE;
};

export const extractUsernameFromContext = (
  appAccessContext: AppAccessContext | null,
) => {
  return isEpcsOrderSigning() ? { username: appAccessContext?.username } : {};
};

export const normalizeFactors = <T extends string = string>(
  factors?: T | T[],
): T[] => {
  return Array.isArray(factors) ? factors : factors ? [factors] : [];
};

// Temporary function to normalize saml data for Epic OAuth
// TODO: Remove when flow has been clarified
export const normalizeSamlDataForEpicOauth = (samlData?: SamlData | string) => {
  if (!samlData) {
    return;
  }

  let decodedSamlData: SamlData | undefined,
    samlAccepted: boolean | undefined,
    samlRequired: boolean | undefined;

  // TODO: Fix, remove, replace, anything this after the flow is clarified more
  if (typeof samlData === 'string') {
    try {
      decodedSamlData = JSON.parse(samlData) as SamlData;
    } catch (e) {
      console.error('Error decoding saml data: ', e);
      // WHAT DO I DO HERE? Incoming S1...

      try {
        decodedSamlData = base64ToJson(samlData) as SamlData;
      } catch (e) {
        console.error('Error on second attempt to decode saml', e);
      }
    }
  } else {
    decodedSamlData = samlData;
  }

  if (decodedSamlData) {
    samlAccepted = decodedSamlData.accepted;
    samlRequired = decodedSamlData.required;
  } else {
    // WHAT DO I DO HERE??? Incoming S1...
    console.error('No decoded saml data');
  }

  return {
    decodedSamlData,
    samlAccepted,
    samlRequired,
  };
};
