import { Alert, Space, Typography } from 'antd';
import { Button } from '@imprivata-cloud/components';

export const OverlayMessage = ({
  onOpen,
  onDisable,
}: {
  onOpen: () => void;
  onDisable: () => void;
}) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pointerEvents: 'auto',
        textAlign: 'center',
      }}
    >
      <Alert
        type="error"
        message={
          <Space direction="vertical" size="large" style={{ padding: 16 }}>
            <Typography.Title level={1}>App Access hidden</Typography.Title>
            <Button
              data-testid="tu--open-window-link"
              onClick={onOpen}
              role="button"
            >
              Open mock test utility
            </Button>
            <Button
              role="button"
              data-testid="tu--disable-test-utility"
              onClick={onDisable}
              variant="link"
            >
              Disable Test Utility
            </Button>
            <Typography.Text style={{ fontSize: '.8rem' }} italic>
              Note: if disabled, you will need to clear storage to re-enable
            </Typography.Text>
          </Space>
        }
      />
    </div>
  );
};
