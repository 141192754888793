import { FatalError, ErrorCode, emitSystemError } from '@imprivata-cloud/authn';
import { debug, type Tracer } from '@imprivata-cloud/common';
import type { CodingContext } from '@imprivata-cloud/data-privacy-js';
import type { UiCaptureCredentialsActionData } from '../store/types';
import type { ApiError } from '../api/types';
import { getCredentials } from '../api/appAccessWebService';
import { CredentialsGetResponseNextAction } from '../shared/types/api';
import { SpanNames } from '../tracing';
import { sendAuthnDataToAgent } from '../agent-api';

export interface GetCredentialsServiceProps {
  tracer: Tracer;
  codingContext?: CodingContext;
  oidcAuthorizationCode?: string;
}

/**
 * @returns UiCaptureCredentialsActionData or boolean to represent a saml authentication
 */
export const getCredentialsService = async ({
  codingContext,
  oidcAuthorizationCode,
  tracer,
}: GetCredentialsServiceProps): Promise<
  UiCaptureCredentialsActionData | boolean
> => {
  debug('[getCredentialsService]', { codingContext: !!codingContext });

  tracer.startSpan(SpanNames.GET_USER_APP_CREDENTIALS);

  const response = await getCredentials().catch((error: unknown) => {
    debug('[getCredentialsService] Error', { error });
    tracer.endSpan(SpanNames.GET_USER_APP_CREDENTIALS, {
      error: error as ApiError,
    });
    throw error;
  });

  debug('[getCredentialsService] Response', { response });
  tracer.endSpan(SpanNames.GET_USER_APP_CREDENTIALS, { response });

  // TODO: Why are we verifying coding context AFTER getCredentials???
  // It's an unnecessary API call.
  if (!codingContext) {
    debug('[getCredentialsService] No coding context found');
    throw emitSystemError({
      code: ErrorCode.INTERNAL,
      message: 'No coding context ',
    });
  }

  switch (response.nextAction) {
    case CredentialsGetResponseNextAction.ENROLL_USER: {
      // If we have a url, redirect
      if (response?.epicAuthorizationUrl) {
        // Temporary: In order for the redirect to happen and the SAME coding context
        // to be used on the redirect BACK, we must store it.
        const storedCodingContext = codingContext.buildImprCodingCtxHeader();
        localStorage.setItem('codingContext', storedCodingContext);

        const workflowId = tracer.getWorkflowId();
        debug('[getCredentialsService] Redirecting to Epic', { workflowId });
        localStorage.setItem('workflowId', workflowId);

        // Actual redirect to epic
        window.location.href = response.epicAuthorizationUrl;
      }

      return true;
    }
    case CredentialsGetResponseNextAction.VERIFY_CREDENTIALS:
      if (response.credentialData?.data) {
        debug('[getCredentialsService] credential data present');
        const decryptedCredentialData = codingContext?.decryptToJson(
          response.credentialData,
        ) as CredentialData;

        debug('[getCredentialsService] passing credential data to agent');
        sendAuthnDataToAgent(decryptedCredentialData, oidcAuthorizationCode);

        return !!decryptedCredentialData?.saml;
      } else {
        debug('[getCredentialsService::error] no credential data found');
        throw new FatalError({
          code: ErrorCode.INTERNAL,
          message: 'No credential data',
        });
      }
    case CredentialsGetResponseNextAction.CAPTURE_CREDENTIALS:
    case CredentialsGetResponseNextAction.CAPTURE_PASSWORD: {
      const isPwCaptureOnly =
        response.nextAction ===
        CredentialsGetResponseNextAction.CAPTURE_PASSWORD;
      debug('[getCredentialsService] password capture? ', isPwCaptureOnly);
      return {
        isPasswordCaptureOnly: isPwCaptureOnly,
        usernameHint: response.usernameHint || '',
        aadUpn: response.aadUpn || '',
      };
    }
    default:
      throw new FatalError({
        code: ErrorCode.INTERNAL,
        message: 'Unknown next-action',
      });
  }
};
