// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

export const TEST_SAML_STRING = jsonToBase64({
  spEntityId: 'https://epic.com',
  audience: 'epic://uscdi',
  recipient: 'https://www.epic.com/prod-env-default',
  transactionId: 'b8aca1aa-b817-11eb-8529-0242ac130003',
});

export const agentData = {
  version: 'mock-1',
  agentVersion: 'mock-21.0924.600',
};

export const deviceData = {
  firmware:
    'pcProxAPI Library Version: 7.6.4; Firmware Version: 14.3.3; Firmware File Name: N/A; CPU0 Application: 1.4.3.3; ' +
    'CPU0 Boot Loader: 0.1.4.4; CPU0 RF Modem: N/A; CPU1 Application: 2.1.3.2; CPU1 Boot Loader: 0.1.4.4; ',
  model: 'RDR-80582AKU',
  productId: '3bfa',
  vendor: 'RF IDeas',
  vendorId: '0c27',
  version: '1.0',
};

export const systemData = {
  version: 'mock',
  osName: 'mock',
  osVersion: 'mock',
  computerName: 'mock',
};
