// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const SpanNames = {
  INIT_APP: 'init_app' as const,
  ACTIVATE_DEVICE: 'activate_device' as const,
  GET_DEVICE_FACTORS: 'get_device_factors' as const,
  GET_ORG_PREFERENCES: 'get_org_preferences' as const,
  GET_FACTOR_OPTIONS: 'get_factor_options' as const,
  SET_WINDOW_STYLE: 'set_window_style' as const,
  SET_AUTHENTICATION_STATUS: 'set_authentication_status' as const,
  SHOW_WINDOW: 'show_window' as const,
  END_JOURNEY_AND_CLOSE_WINDOW: 'end_journey_and_close_window' as const,
  FACTOR_DATA_READY: 'factor_data_ready' as const,

  CREATE_AUTHN_SESSION: 'create_authn_session' as const,
  AUTHENTICATE_REQUEST: 'authenticate_request' as const,

  CREATE_ENROLLMENT_SESSION: 'create_enrollment_session' as const,
  ENROLLMENT_REQUEST: 'enrollment_request' as const,

  SUBMIT_CREDENTIALS: 'submit_credentials' as const,
  SET_OIDC_AUTH_CODE: 'set_oidc_auth_code' as const,

  GET_PUSH_STATUS: 'get_push_status' as const,
  GET_APP_ACCESS_CONTEXT: 'get_app_access_context' as const,

  GET_PKCE_DATA: 'get_pkce_data' as const,
  GET_IDENTITY_TOKEN: 'get_identity_token' as const,
  GET_COMPONENT_INFORMATION: 'get_component_information' as const,
  GET_SESSION_DATA: 'get_session_data' as const,
  SET_SESSION_DATA: 'set_session_data' as const,
  VERIFY_ENROLLMENT: 'verify_enrollment' as const,

  START_CLIENT_USER_SESSION: 'start_client_user_Session' as const,
  GET_USER_APP_CREDENTIALS: 'get_user_app_credentials' as const,
  PROCESS_CAPTURED_CREDENTIALS: 'process_captured_credentials' as const,
  PROCESS_LOGIN_RESULT: 'process_login_result' as const,
};

export const TraceContexts = {
  AUTHN_DATA_READY: 'AUTHN_DATA_READY' as const,
};
