// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum ApiErrorType {
  APP = 'app',
  SYSTEM = 'system',
}

export enum ErrorCode {
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  NOT_FOUND = 'not-found',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  PERMISSION_DENIED = 'forbidden',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  RESOURCE_EXHAUSTED = 'RESOURCE_EXHAUSTED',
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  ABORTED = 'ABORTED',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  INTERNAL = 'INTERNAL',
  UNAVAILABLE = 'UNAVAILABLE',
  DATA_LOSS = 'DATA_LOSS',
  INVALID_CREDENTIALS = 'invalid-credentials',
  AMBIGUOUS_USERNAME = 'ambiguous-username',
  INVALID_TENANT_ID = 'invalid-tenant-id',
  CREDENTIAL_EXPIRED = 'credential-expired',
  ACCOUNT_DISABLED = 'idp-disabled',
  FATAL = 'fatal',
  // Real life code returned when app not found by name
  APP_NOT_CONFIGURED = 'app-not-configured',
  SESSION_EXPIRED = 'session-expired',
  // Real life
  MISSING_TENANT_ID = 'missing-tenant-id',
  PUSH_NOTIFICATION_REJECTED = 'PUSH_NOTIFICATION_REJECTED',
  IID_AUTH_ERROR = 'IID_AUTH_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',

  TERMINATE_BY_TIMEOUT = 'timeout',
}

export interface ApiError {
  code: ErrorCode | string;
  type: ApiErrorType;
  message?: string;
  data?: Record<string, unknown>;
}

export interface ApiErrorResponseBody {
  error: ApiError;
}

export type ApiResponse<T> = T | ApiErrorResponseBody;

export type ApiRequest<T> = T;
