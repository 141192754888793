// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { AuthnMethod } from '@imprivata-cloud/authn';
import type { TraceContext } from '@imprivata-cloud/common/types';
import type { MemorySection } from './MemorySection';
import type { AuthenticationStatusCode } from '../../types';
import { closeWindow } from '../utils';
import { tracer } from '../../tracing';
import { AgentEvent } from '../../constants';

const EPIC_CONNECTOR_TIMEOUT = 100;

export class AuthnSection {
  constructor(private readonly _memory: MemorySection) {}

  getDeviceFactorTypes(
    traceContext: TraceContext,
    callback: (types: AuthnMethod[]) => void,
  ) {
    tracer.startSpanFromContext('get_device_factor_types', traceContext);
    tracer.endSpan('get_device_factor_types');
    callback(Array.from(this._memory.connectedDevices.keys()));
  }

  getAppAccessContext(
    journeyId: string,
    traceContext: TraceContext,
    callback: (appAccessContext: string) => void,
  ) {
    tracer.startSpanFromContext('get_app_access_context', traceContext);
    tracer.addSpanAttributes('get_app_access_context', {
      journeyId,
    });
    callback(this._memory.appAccessContext);
    tracer.endSpan('get_app_access_context');
  }

  submitCredentials2(
    journeyId: string,
    traceContext: TraceContext,
    credentials: CredentialData,
  ) {
    tracer.startSpanFromContext('submit_credentials', traceContext);
    tracer.addSpanAttributes('submit_credentials', {
      journeyId,
      credentials,
    });
    this._memory.submittedCredentials.push(credentials);

    tracer.endSpan('submit_credentials');
  }

  setAuthZCode(
    journeyId: string,
    traceContext: TraceContext,
    oidcAuthCode: string,
  ) {
    tracer.startSpanFromContext('set_oidc_auth_code', traceContext);
    tracer.addSpanAttributes('set_oidc_auth_code', {
      journeyId,
      oidcAuthCode,
    });
    this._memory.submittedOidcAuthCodes.push(oidcAuthCode);

    tracer.endSpan('set_oidc_auth_code');
  }

  endJourneyAndCloseWindow(
    journeyIdToClose: string,
    traceContext: TraceContext,
  ) {
    tracer.startSpanFromContext('close_agent', traceContext);
    tracer.addSpanAttributes('close_agent', { journeyIdToClose });
    tracer.endAllSpans();
    tracer.forceFlush();

    closeWindow();
  }

  setAuthenticationStatus(
    journeyId: string,
    traceContext: TraceContext,
    statusCode: AuthenticationStatusCode,
  ) {
    // At this moment the EA asks the Epic Conector whether it is ready to proceed.
    // Then it emits 'app-authentication-status' event with statusCode 'ready' or 'not-ready'
    // depending on the epic response.
    // Mock implementation emits just the same statusCode that was received from the UI
    tracer.startSpanFromContext('set_authentication_status', traceContext);
    tracer.addSpanAttributes('set_authentication_status', {
      journeyId,
      statusCode,
    });

    const eventName = AgentEvent.APP_AUTHENTICATION_STATUS;
    const callback = this._memory.registeredCallbacks[eventName];

    if (callback) {
      setTimeout(() => {
        callback(eventName, {
          traceContext: tracer.getTraceContext(),
          statusData: {
            statusCode,
          },
        });
      }, EPIC_CONNECTOR_TIMEOUT);
    }
    tracer.endSpan('set_authentication_status');
  }

  getPKCEData(
    workflowId: string,
    traceContext: TraceContext,
    callback: (codeChallenge: string, codeChallengeMethod: string) => void,
  ) {
    tracer.startSpanFromContext('get_pkce_data', traceContext);
    tracer.addSpanAttributes('get_pkce_data', {
      workflowId,
    });
    callback(this._memory.codeChallenge, this._memory.codeChallengeMethod);
    tracer.endSpan('get_pkce_data');
  }

  getIdentityToken(
    workflowId: string,
    traceContext: TraceContext,
    callback: (identityToken: string, codingContext: string) => void,
  ) {
    tracer.startSpanFromContext('get_identity_token', traceContext);
    tracer.addSpanAttributes('get_identity_token', {
      workflowId,
    });
    callback(this._memory.identityToken, this._memory.codingContext);
    tracer.endSpan('get_identity_token');
  }
}
