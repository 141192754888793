// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const APP_ACCESS_SERVICE_PREFIX = '/app-access-web/v1';
// https://github.com/imprivata-cloud/saas-api-idls/blob/main/openapi/app-access-web/v1/openapi/spec.yaml
export const appAccessService = {
  START_APPLICATION_ACCESS:
    `${APP_ACCESS_SERVICE_PREFIX}/application-access/start` as const,
  VERIFY_ENROLLMENT:
    `${APP_ACCESS_SERVICE_PREFIX}/factor/enrollment/verify` as const,
  GET_CREDENTIALS: `${APP_ACCESS_SERVICE_PREFIX}/credentials/get` as const,
  PROCESS_CAPTURED_CREDENTIALS:
    `${APP_ACCESS_SERVICE_PREFIX}/captured-credentials/process` as const,
  PROCESS_LOGIN_RESULT:
    `${APP_ACCESS_SERVICE_PREFIX}/login-result/process` as const,
};
