import { ContextType } from '@imprivata-cloud/authn';
import { catchError, filter, from, of, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import type { Action, RootState, EpicDependencies } from '../types';
import type { ApiError } from '../../api/types';
import {
  processLoginResultAction,
  resetStateAction,
  uiCaptureCredentialsAction,
} from '../actions';
import { LoginResultProcessResponseNextAction } from '../../api/types';
import { tracer, SpanNames } from '../../tracing';
import i18n from '../../i18n';
import { getContextType } from '../../utils/utils';
import { authenticationEndJourney } from './agentEpic';
import { AuthenticationResultStatusCode } from '../../constants';
import { log } from '../../utils/logging';

const SPAN_NAME = SpanNames.PROCESS_LOGIN_RESULT;

/**
 * Get applicable banner message based on the current context type.
 * @param contextType - the context type for which a banner message should be returned
 * @returns the primary and secondary banner message as an object
 */
export function getBannerMessage(contextType: string): {
  primary: string;
  secondary: string;
} {
  switch (contextType) {
    case ContextType.DESKTOP_ACCESS:
      return {
        primary: i18n.t('learn-credentials.UXID_DA_LOGIN_FAIL.message') || '',
        secondary:
          i18n.t('learn-credentials.UXID_DA_LOGIN_FAIL.description') || '',
      };
    case ContextType.CLINICAL_APP_LOGIN:
      return {
        primary: i18n.t('learn-credentials.UXID_EPIC_LEARN_FAIL.message') || '',
        secondary:
          i18n.t('learn-credentials.UXID_EPIC_LEARN_FAIL.description') || '',
      };
    default:
      log(
        `Unknown context type ${contextType} provided when getting banner message`,
      );
      return {
        primary: i18n.t('learn-credentials.UXID_EPIC_LEARN_FAIL.message') || '',
        secondary:
          i18n.t('learn-credentials.UXID_EPIC_LEARN_FAIL.description') || '',
      };
  }
}

export const processLoginResultEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'processLoginResult' | 'endJourneyAndCloseWindow'>
> = (action$, _state$, dependencies) => {
  const { processLoginResult, endJourneyAndCloseWindow } = dependencies;

  return action$.pipe(
    filter(isActionOf(processLoginResultAction.request)),
    switchMap(({ payload: verifyStatus }) => {
      tracer.startSpan(SPAN_NAME);
      const UXID_LEARN_FAIL_BANNER = getBannerMessage(getContextType());
      const resetStateActionCredentialLearnFail = resetStateAction(
        UXID_LEARN_FAIL_BANNER,
      );

      return from(processLoginResult({ verifyStatus })).pipe(
        switchMap(response => {
          tracer.endSpan(SPAN_NAME, { response });
          const nextAction = response.nextAction;
          if (
            verifyStatus === AuthenticationResultStatusCode.SUCCESS &&
            nextAction === LoginResultProcessResponseNextAction.NONE
          ) {
            authenticationEndJourney(endJourneyAndCloseWindow);
            return of(resetStateAction(null));
          } else if (
            [
              LoginResultProcessResponseNextAction.CAPTURE_CREDENTIALS,
              LoginResultProcessResponseNextAction.CAPTURE_PASSWORD,
            ].includes(nextAction)
          ) {
            return of(
              uiCaptureCredentialsAction({
                isPasswordCaptureOnly:
                  nextAction ===
                  LoginResultProcessResponseNextAction.CAPTURE_PASSWORD,
                usernameHint: response.usernameHint || '',
                aadUpn: response.aadUpn || '',
                banner: UXID_LEARN_FAIL_BANNER,
              }),
            );
          }
          return of(resetStateActionCredentialLearnFail);
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(SPAN_NAME, { error });
          return of(resetStateActionCredentialLearnFail);
        }),
      );
    }),
  );
};
