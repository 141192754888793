import { Space, Typography, type SpaceProps } from 'antd';
import { Title } from './Title';

export const Section = ({
  header,
  headerButton,
  footer,
  verticalContext,
  children,
  ...props
}: SpaceProps & {
  header: string | React.ReactNode;
  headerButton?: React.ReactNode;
  footer?: React.ReactNode;
  verticalContext?: boolean;
}) => {
  return (
    <Space
      direction="vertical"
      size="small"
      {...props}
      style={{
        padding: '0 16px',
        width: '100%',
        ...(props.style || {}),
      }}
    >
      <Title style={{ width: '100%' }}>
        <Space direction="horizontal">
          {typeof header === 'string' ? (
            <Typography.Text strong>{header}</Typography.Text>
          ) : (
            header
          )}
          {headerButton}
        </Space>
      </Title>
      <Space direction={verticalContext ? 'vertical' : 'horizontal'}>
        {children}
      </Space>
      {footer}
    </Space>
  );
};
