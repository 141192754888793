// Copyright 2023, Imprivata, Inc.  All rights reserved.

import type { Secret } from '@imprivata-cloud/data-privacy-js';

export interface ApplicationAccessStartRequest {
  contextType: string;
  resourceType: string;
  epicData?: EpicData;
  desktopData?: DesktopData;
}

export interface VerifyEnrollmentRequest {
  factorData: Secret;
}

export interface VerifyEnrollmentResponse {
  isEnrolled: boolean;
}

export interface ApplicationAccessStartResponse {
  nextAction: ApplicationAccessStartResponseNextAction;
}

export interface EpicData {
  appSamlData?: string;
  // ADDED
  samlAccepted?: boolean;
  samlRequired?: boolean;
  interconnectBaseUrl?: string;
  fhirServerUrl?: string;
}

export interface DesktopData {
  clientCredentials?: GenericClientCredentials;
}

export interface GenericClientCredentials {
  username: string;
  password: Secret;
  domain?: string;
}

export enum ApplicationAccessStartResponseNextAction {
  GET_APP_CREDENTIALS = 'get-app-credentials',
}
