// Copyright 2022, Imprivata, Inc.  All rights reserved.

import ReactDOM from 'react-dom';
import { TestUtility } from './TestUtility';

export interface ITestUtility {
  tapProximityCard: () => void;
}

export function attachTestUtility(): void {
  const container = createAndRenderContainer();

  ReactDOM.render(<TestUtility />, container);
}

function createAndRenderContainer() {
  const container = document.createElement('div');
  container.id = 'test-utility-container';

  container.style.bottom = '0';
  container.style.display = 'block';
  container.style.height = '100vh';
  container.style.left = '0';
  container.style.pointerEvents = 'none';
  container.style.position = 'fixed';
  container.style.right = '0';
  container.style.top = '0';
  container.style.width = '100vw';

  document.body.append(container);

  return container;
}
