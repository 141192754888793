// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useRef, useState } from 'react';

type ISupportedValues = Record<string, number | string | boolean>;

type StateHook = <T extends ISupportedValues>(
  startValues: T,
) => [T, (values: T) => void];

type StateHookWithStorage = <T extends ISupportedValues>(
  startValues: T,
  storage: Storage,
) => [T, (values: T) => void];

export const usePersistentState: StateHook = startValues =>
  usePersistentStateForStorage(startValues, localStorage);

export const usePersistentSessionState: StateHook = startValues =>
  usePersistentStateForStorage(startValues, sessionStorage);

export const usePersistentStateForStorage: StateHookWithStorage = (
  startValues,
  storage,
) => {
  let mergedStartValues = { ...startValues };

  const isFirstRender = useRef(true);
  if (isFirstRender.current) {
    const retrievedValues: ISupportedValues = {};
    Object.keys(startValues).forEach(key => {
      const retrievedValue = storage.getItem(key);
      if (retrievedValue !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        retrievedValues[key] = JSON.parse(retrievedValue);
      }
    });
    mergedStartValues = { ...startValues, ...retrievedValues };

    isFirstRender.current = false;
  }

  const [values, setValues] = useState(mergedStartValues);

  const storeValues = (newValues: typeof values) => {
    Object.keys(newValues).forEach(key => {
      storage.setItem(key, JSON.stringify(newValues[key]));
    });
    setValues(newValues);
  };

  return [values, storeValues];
};
