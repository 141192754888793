// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { Loggable } from '@imprivata-cloud/common';
import {
  log as commonLog,
  debug as commonDebug,
} from '@imprivata-cloud/common';
import { tracer } from '../tracing';

export function log(message: string, ...messageParts: Array<Loggable>) {
  logMessage(commonLog, message, ...messageParts);
}

export function debug(message: string, ...messageParts: Array<Loggable>) {
  logMessage(commonDebug, message, ...messageParts);
}

function logMessage(
  logFunction: (...messageParts: Array<Loggable>) => void,
  message: string,
  ...messageParts: Array<Loggable>
) {
  const delimiter = messageParts?.length > 0 ? ', ' : '';
  logFunction(
    `[AppAccess] ${message} (`,
    ...messageParts,
    `${delimiter}workflowId: ${tracer?.getWorkflowId()} )`,
  );
}
