// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common/types';
import type { SessionData, SessionDataKey } from '../../agent-api/types';
import type { MemorySection } from './MemorySection';
import { tracer } from '../../tracing';

export class SessionSection {
  constructor(private readonly _memory: MemorySection) {}

  getData(
    workflowId: string,
    traceContext: TraceContext,
    keys: SessionDataKey[],
    callback: (sessionData: SessionData) => void,
  ) {
    tracer.startSpanFromContext('get_session_data', traceContext);
    tracer.addSpanAttributes('get_session_data', {
      workflowId,
      keys,
    });
    callback(this._memory.sessionData);
    tracer.endSpan('get_session_data');
  }

  setData(_: string, traceContext: TraceContext, sessionData: SessionData) {
    tracer.startSpanFromContext('set_session_data', traceContext);
    this._memory.sessionData = sessionData;
    tracer.endSpan('set_session_data');
  }
}
