// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios from 'axios';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import { QueryParams } from '../utils/constants';
import { Headers } from '../shared/types/common';
import { WEB_APP_NAME } from '../shared/constants';
import { getQueryParams } from '../utils/utils';
import { tracer } from '../tracing';

const securityHeaders = {
  [Headers.StrictTransportSecurity]: 'max-age=86400;includeSubDomains',
  [Headers.XSSProtection]: '1;mode=block',
  [Headers.XFrameOptions]: 'DENY',
  [Headers.XContentTypeOptions]: 'nosniff',
};

export const client = axios.create({
  withCredentials: true,
  headers: {
    ...securityHeaders,
    [Headers.ImprTenantId]: getQueryParams().get(QueryParams.TENANT_ID) || '',
    [Headers.ImprClientName]: jsonToBase64({ name: WEB_APP_NAME }),
    [Headers.ImprWorkflowId]: tracer.getWorkflowId() || '',
  },
});
