/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { AuthnRequest, OidcRequestData } from '@imprivata-cloud/authn';
import { none, some } from 'fp-ts/Option';
import type { Reducer } from 'redux';
import type { PartialBannerProps } from './actions';
import type {
  RootState,
  Action,
  UiCaptureCredentialsActionData,
  AppAccessContext,
} from './types';
import {
  resetAuthnDataAction,
  saveAuthnDataAction,
  errorAction,
  getCredentialsAction,
  saveAppAccessContext,
  startApplicationAccessAction,
  savePkceData,
  processCapturedCredentialsAction,
  uiCaptureCredentialsAction,
  resetStateAction,
  clearError,
  readIdentityTokenDataAction,
  saveAgentSaml,
  saveCodingContextAction,
  isSamlAuthenticationAction,
  setInternetConnectivityStatusAction,
  setAuthenticationStatusAction,
  readSessionDataAction,
} from './actions';
import { InternetConnectivityStatus } from '../constants';

export const initialState: RootState = {
  error: null,
  appAccessContext: null,
  pkceData: null,
  authnData: null,
  uiCaptureCredentials: null,
  agentSaml: null,
  networkConnectionStatus: InternetConnectivityStatus.CONNECTED,
};

export const defaultErrorBannerProps: PartialBannerProps = {};

const reducer: Reducer<RootState, Action> = combineReducers<RootState, Action>({
  error: createReducer(initialState.error)
    .handleAction(
      resetStateAction,
      (_: RootState, { payload }: { payload: PartialBannerProps }) => payload,
    )
    .handleAction(
      uiCaptureCredentialsAction,
      (
        _: RootState,
        { payload }: { payload: UiCaptureCredentialsActionData },
      ) => payload.banner || null,
    )
    .handleAction(
      // TODO - Use error codes for memoization.
      [errorAction, getCredentialsAction.failure],
      (_: RootState) => ({
        primary:
          'An unexpected problem occurred. \nWe are working to resolve it.',
      }),
    )
    .handleAction(
      [
        startApplicationAccessAction.failure,
        readIdentityTokenDataAction.failure,
        readSessionDataAction.failure,
      ],
      () => defaultErrorBannerProps,
    )
    .handleAction(
      [
        startApplicationAccessAction.request,
        getCredentialsAction.request,
        processCapturedCredentialsAction.request,
        readIdentityTokenDataAction.request,
        readSessionDataAction.request,
        startApplicationAccessAction.success,
        getCredentialsAction.success,
        processCapturedCredentialsAction.success,
        readIdentityTokenDataAction.success,
        readSessionDataAction.success,
        setAuthenticationStatusAction,
        clearError,
      ],
      () => null,
    ),
  appAccessContext: createReducer(initialState.appAccessContext)
    .handleAction(resetStateAction, (rootState: RootState) => ({
      ...rootState,
      codingContext: initialState.appAccessContext?.codingContext,
      isSamlAuthFlow: initialState.appAccessContext?.isSamlAuthFlow,
    }))
    .handleAction(
      saveAppAccessContext,
      (_: RootState, { payload }: { payload: AppAccessContext }) => payload,
    )
    .handleAction(
      saveCodingContextAction,
      (rootState: RootState, { payload }: { payload: AppAccessContext }) => ({
        ...rootState,
        codingContext: payload,
      }),
    )
    .handleAction(
      isSamlAuthenticationAction,
      (rootState: RootState, { payload }: { payload: boolean }) => ({
        ...rootState,
        isSamlAuthFlow: payload,
      }),
    ),

  pkceData: createReducer(initialState.pkceData).handleAction(
    savePkceData,
    (_: RootState, { payload }: { payload: OidcRequestData }) => payload,
  ),
  authnData: createReducer(initialState.authnData)
    .handleAction(
      saveAuthnDataAction,
      (_: RootState, { payload }: { payload: AuthnRequest }) => some(payload),
    )
    .handleAction(
      [
        resetStateAction,
        resetAuthnDataAction,
        readIdentityTokenDataAction.success,
        readSessionDataAction.success,
        readSessionDataAction.failure,
      ],
      (_: RootState) => none,
    ),
  uiCaptureCredentials: createReducer(initialState.uiCaptureCredentials)
    .handleAction(resetStateAction, () => initialState.uiCaptureCredentials)
    .handleAction(
      uiCaptureCredentialsAction,
      (
        _: RootState,
        {
          payload,
        }: {
          payload: UiCaptureCredentialsActionData;
        },
      ) => {
        return payload;
      },
    )
    .handleAction(
      [processCapturedCredentialsAction.request],
      (rootState: RootState) => ({
        ...rootState,
        backendVerificationInProgress: true,
      }),
    )
    .handleAction(
      [processCapturedCredentialsAction.failure],
      (rootState: RootState) => ({
        ...rootState,
        backendVerificationInProgress: false,
      }),
    )
    .handleAction(
      [processCapturedCredentialsAction.success],
      (rootState: RootState) => ({
        ...rootState,
        backendVerificationInProgress: false,
        agentVerificationInProgress: true,
      }),
    ),
  agentSaml: createReducer(initialState.agentSaml).handleAction(
    saveAgentSaml,
    (_: RootState, { payload }: { payload: string }) => payload,
  ),
  networkConnectionStatus: createReducer(
    initialState.networkConnectionStatus,
  ).handleAction(
    setInternetConnectivityStatusAction,
    (_: RootState, { payload }: { payload: boolean }) => payload,
  ),
});

export default reducer;
