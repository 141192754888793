// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { base64ToString } from '@imprivata-cloud/data-privacy-js';
import type { SessionData } from '../agent-api/types';
import type { AgentApi } from './types';
import {
  MemorySection,
  EventSection,
  AuthnSection,
  ConfigSection,
  OtelSection,
  WindowSection,
  AgentSection,
} from './sections';
import { SessionSection } from './sections/SessionSection';
import { UpdateManagerSection } from './sections/UpdateManagerSection';

export class EndpointAgentMock implements AgentApi {
  _memory = new MemorySection();
  _config = new ConfigSection(this._memory);

  otel = new OtelSection();
  window = new WindowSection(this._memory);
  event = new EventSection(this._memory);
  authn = new AuthnSection(this._memory);
  agent = new AgentSection();
  session = new SessionSection(this._memory);
  update_manager = new UpdateManagerSection(this._memory);

  constructor() {
    const storedAppContext = sessionStorage.getItem('app_access_context');
    if (storedAppContext) {
      this._config.setAppAccessContext(storedAppContext);
    }
    const storedIdentityToken = sessionStorage.getItem('identity_token');
    if (storedIdentityToken) {
      this._config.setIdentityToken(storedIdentityToken);
    }
    const storedCodingContext = sessionStorage.getItem('coding_context');
    if (storedCodingContext) {
      this._config.setCodingContext(storedCodingContext);
    }
    const storedSystemData = sessionStorage.getItem('agent_system_data');
    if (storedSystemData) {
      this.agent.systemData = base64ToString(storedSystemData);
    }
    const storedSessionData = sessionStorage.getItem('session_data');
    if (storedSessionData) {
      this._config.setSessionData(
        JSON.parse(base64ToString(storedSessionData)) as SessionData,
      );
    }
  }
}
