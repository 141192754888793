import { ErrorCode as AuthnErrorCode } from '@imprivata-cloud/authn';
import type { Dispatch } from 'redux';
import { setInternetConnectivityStatusAction } from '../store/actions';
import {
  endJourneyAndCloseWindow,
  setAuthenticationStatus,
} from '../agent-api';
import { tracer } from '../tracing';
import {
  AuthenticationReadinessStatusCode,
  InternetConnectivityStatus,
} from '../constants';
import { isDesktopAccess } from '../utils/utils';
import { ErrorCode } from './new/types';
import { log } from '../utils/logging';

export const mapErrorToAction = (
  error: ErrorCode | AuthnErrorCode,
  networkConnectionStatus: InternetConnectivityStatus,
  dispatch: Dispatch,
): void => {
  log('Handled error:', error);
  switch (error) {
    case ErrorCode.IID_AUTH_ERROR:
    case ErrorCode.PUSH_NOTIFICATION_REJECTED:
    case ErrorCode.TERMINATE_BY_TIMEOUT:
      endJourneyAndCloseWindow(
        tracer.getWorkflowId(),
        tracer.getTraceContext(),
      );
      break;
    case AuthnErrorCode.NETWORK_ERROR:
    case ErrorCode.NETWORK_ERROR:
      // in this case first step is to show banner to user, and if user tries to authn - put app-access into offline-mode (setAuthenticationStatus(offline))
      // do this only for DA
      if (isDesktopAccess()) {
        dispatch(
          setInternetConnectivityStatusAction(
            InternetConnectivityStatus.DISCONNECTED,
          ),
        );
        if (
          networkConnectionStatus === InternetConnectivityStatus.DISCONNECTED
        ) {
          setAuthenticationStatus(AuthenticationReadinessStatusCode.OFFLINE);
        }
      }
      break;
  }
};
