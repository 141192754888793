// Copyright 2023, Imprivata, Inc.  All rights reserved.

import { Tooltip } from 'antd';
import { useState } from 'react';
import { InputBox } from '@imprivata-cloud/components';
import { base64ToString, jsonToBase64 } from '@imprivata-cloud/data-privacy-js';

import { usePersistentSessionState } from '../../hooks';
import { Section } from '../components/Section';

/**
 * Provides a way for the user to set the system data provided by the Endpoint Agent.
 * An example system data object may look like the following:
 *
 * ```json
 * {
 *   "computerName": "NY-Radiology-PC01"
 * }
 * ```
 */
export const SystemDataSection = () => {
  const [state, setState] = usePersistentSessionState({
    agent_system_data: '',
  });
  const [encodingError, setEncodingError] = useState(false);

  return (
    <Section header="System Data">
      <InputBox
        type="text"
        size="small"
        placeholder="System Data"
        status={encodingError ? 'error' : ''}
        defaultValue={base64ToString(state.agent_system_data)}
        onChange={event => {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const encodedContext = JSON.parse(event.target.value);
            setState({
              agent_system_data: jsonToBase64(encodedContext),
            });
            setEncodingError(false);
          } catch {
            setEncodingError(true);
          }
        }}
      />

      {encodingError && (
        <Tooltip title="String is not in correct JSON format">
          <span>(!)</span>
        </Tooltip>
      )}
    </Section>
  );
};
