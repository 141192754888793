// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { AuthnMethod } from '@imprivata-cloud/authn';
import type {
  AppAuthenticationStatusEventHandler,
  FactorDataReadyEventHandler,
  FactorFilter,
  AgentEventHandler,
} from '../../types';
import type { MemorySection } from './MemorySection';
import { AgentEvent } from '../../constants';

export type GetEventHandler<EventType extends AgentEvent> =
  EventType extends AgentEvent.FACTOR_DATA_READY
    ? FactorDataReadyEventHandler
    : AppAuthenticationStatusEventHandler;

export class EventSection {
  constructor(private readonly _memory: MemorySection) {}

  on2(
    eventTypes: AgentEvent[],
    _: string,
    filter: FactorFilter,
    callback: AgentEventHandler,
  ) {
    eventTypes.forEach(eventType => {
      // 'factor-changed' and 'factor-data-ready' callbacks should be passed with the list of interested factors
      // if this list is missing - do nothing
      if (
        (eventType === AgentEvent.FACTOR_CHANGED ||
          eventType === AgentEvent.FACTOR_DATA_READY) &&
        !filter?.factorType.includes(AuthnMethod.PROX)
      ) {
        return;
      }

      // eslint-disable-next-line
      // @ts-ignore
      this._memory.registeredCallbacks[eventType] = callback;
    });
  }
}
