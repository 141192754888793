// Copyright 2022, Imprivata, Inc.  All rights reserved.

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextOptions from './i18nextOptions';

const i18n = i18next.createInstance();

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextOptions())
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  .catch(err => err);

export default i18n;
