// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { AuthBannerType, AuthBanner } from '@imprivata-cloud/components';
import type { RootState } from '../../store/types';
import {
  Card,
  CenteringContainer,
  PaddedContainer,
  UsernamePasswordForm,
} from '../../components';
import {
  processCapturedCredentialsAction,
  resetStateAction,
  clearError,
} from '../../store/actions';
import { LearnCredentialsType } from '../../store/types';
import {
  LEARN_CREDENTIALS_DISPLAY_TIMEOUT_SEC,
  USER_ACTIVITY_EVENTS,
} from '../../constants';

const credentialTypeToFormMapper = {
  [LearnCredentialsType.DESKTOP_ACCESS]: 'form-da',
  [LearnCredentialsType.EPIC_HYPERDRIVE]: 'form-epic',
};

export type LearnCredentialsProps = {
  credentialType: LearnCredentialsType;
};

export const LearnCredentials = (props: LearnCredentialsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { credentialType } = props;
  const {
    error,
    codingContext,
    backendVerificationInProgress,
    agentVerificationInProgress,
    isPasswordCaptureOnly,
    usernameHint,
    aadUpn,
  } = useSelector((state: RootState) => ({
    error: state.error,
    codingContext: state.appAccessContext?.codingContext,
    isPasswordCaptureOnly:
      state.uiCaptureCredentials?.isPasswordCaptureOnly || false,
    usernameHint: state.uiCaptureCredentials?.usernameHint,
    backendVerificationInProgress:
      state.uiCaptureCredentials?.backendVerificationInProgress || false,
    agentVerificationInProgress:
      state.uiCaptureCredentials?.agentVerificationInProgress || false,
    aadUpn: state.uiCaptureCredentials?.aadUpn || '',
  }));
  const [values, setValues] = useState<{ username: string; password: string }>({
    username: usernameHint || '',
    password: '',
  });

  useEffect(() => {
    if (error) {
      setValues({
        username: usernameHint || '',
        password: '',
      });
    }
    // I do not want usernameHint here
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useIdleTimer({
    timeout: LEARN_CREDENTIALS_DISPLAY_TIMEOUT_SEC * 1000,
    events: USER_ACTIVITY_EVENTS,
    onIdle: () => {
      dispatch(resetStateAction(null));
    },
  });

  const formName = credentialTypeToFormMapper[credentialType];
  return (
    <CenteringContainer>
      <Card>
        {error && (
          <AuthBanner
            type={AuthBannerType.ERROR}
            primary={error.primary || ''}
            secondary={error.secondary || ''}
            onClose={() => dispatch(clearError())}
          />
        )}
        <PaddedContainer>
          <UsernamePasswordForm
            data-testid="learn-credentials-form"
            values={values}
            onChange={values => {
              setValues(values);
            }}
            onSubmit={() => {
              if (codingContext) {
                dispatch(
                  processCapturedCredentialsAction.request({
                    ...values,
                    codingContext,
                    isPasswordCaptureOnly,
                  }),
                );
              }
            }}
            onCancel={() => {
              dispatch(resetStateAction(null));
            }}
            disabled={
              !values.username ||
              !values.password ||
              backendVerificationInProgress ||
              agentVerificationInProgress
            }
            title={t(
              isPasswordCaptureOnly
                ? `learn-credentials.${formName}.title-pass`
                : `learn-credentials.${formName}.title-cred`,
            )}
            info={t(`learn-credentials.${formName}.info`)}
            userPrincipalName={aadUpn}
            usernameLabel={t(`learn-credentials.${formName}.username-label`)}
            passwordLabel={t(`learn-credentials.${formName}.password-label`)}
            capsLockTooltipLabel={t('password.caps-lock-tooltip')}
            submitButtonText={t(
              `learn-credentials.${formName}.submit-button-text`,
            )}
            cancelButtonText={t('actions.cancel')}
            usernameDisabled={isPasswordCaptureOnly}
          />
        </PaddedContainer>
      </Card>
    </CenteringContainer>
  );
};
