// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common/types';
import type { MemorySection } from './MemorySection';
import type { ComponentData, ComponentId } from '../../agent-api/types';
import { tracer } from '../../tracing';

export class UpdateManagerSection {
  constructor(private readonly _memory: MemorySection) {}

  getComponentInformation(
    workflowId: string,
    traceContext: TraceContext,
    componentId: ComponentId,
    callback: (data?: ComponentData) => void,
  ) {
    tracer.startSpanFromContext('get_component_information', traceContext);
    tracer.addSpanAttributes('get_component_information', {
      workflowId,
      componentId,
    });
    callback(this._memory.agentComponents.get(componentId));
    tracer.endSpan('get_component_information');
  }
}
