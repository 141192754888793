// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { type InitOptions } from 'i18next';
import LocalResourcesBackend from 'i18next-resources-to-backend';
import { resources, defaultNS, ns } from './resources';

const baseI18nextOptions: InitOptions = {
  // debug: true, // uncomment to debug
  defaultNS,
  ns,
  lng: 'en',
  fallbackLng: 'en',
  returnEmptyString: false,

  backend: {
    backends: [LocalResourcesBackend(resources)],
  },

  detection: {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
  },

  react: {
    useSuspense: false,
  },
};

const i18nextOptions = (options = {}): InitOptions => {
  return {
    ...baseI18nextOptions,
    ...options,
  };
};

export default i18nextOptions;
