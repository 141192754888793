// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tracer, TracingSolutionType } from '@imprivata-cloud/common';
import getConfig from '../appConfigUtils';
import { SpanNames } from './constants';

export * from './constants';

let instance: Tracer;

export const createTracer = (): Tracer => {
  if (!instance) {
    const {
      ZIPKIN_TRACING_URL,
      OTLP_TRACING_URL,
      COLLECT_TRACING,
      TRACING_SOLUTION_TYPE,
    } = getConfig();
    console.log(getConfig());

    const queryParams = new URLSearchParams(window.location.search);

    // TODO: once no more Endpoint Agent versions are sending journeyId,
    // remove the journeyId fallback in story VN-20163
    const workflowId =
      queryParams.get('workflowId') ??
      (queryParams.get('journeyId') ||
        localStorage.getItem('workflowId') ||
        '');
    const spanContext = queryParams.get('spanContext');
    const initialSpan = spanContext
      ? {
          spanName: SpanNames.INIT_APP,
          spanContext,
        }
      : undefined;

    const tracingSolutionType =
      TracingSolutionType[
        TRACING_SOLUTION_TYPE as keyof typeof TracingSolutionType
      ];

    instance = new Tracer(
      'app-access-web-ui',
      workflowId || '',
      {
        zipkinExportUrl: ZIPKIN_TRACING_URL,
        otlpExportUrl: OTLP_TRACING_URL,
        collectSpans: COLLECT_TRACING === 'true',
      },
      initialSpan,
      ...(tracingSolutionType !== undefined ? [tracingSolutionType] : []),
    );
  }
  return instance;
};

export const tracer = createTracer();
