// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum Headers {
  ImprTenantId = 'Impr-Tenant-Id',
  ImprSessionId = 'Impr-Session-Id',
  ImprWorkflowId = 'Impr-Workflow-Id',
  ImprCodingCtx = 'Impr-Coding-Ctx',
  ImprClientName = 'Impr-Client-Name',
  ImprAuthorization = 'Impr-Authorization',
  XContentTypeOptions = 'X-Content-Type-Options',
  XSSProtection = 'X-XSS-Protection',
  XFrameOptions = 'X-Frame-Options',
  StrictTransportSecurity = 'Strict-Transport-Security',
}
