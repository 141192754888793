// Copyright 2023, Imprivata, Inc.  All rights reserved.

import { Button, Select } from '@imprivata-cloud/components';
import { InternetConnectivityStatus } from '../../../constants';
import { usePersistentSessionState } from '../../hooks';
import { Section } from '../components/Section';

export const OfflineModeSection = () => {
  const [state, setState] = usePersistentSessionState({
    tu__connectivity_status: InternetConnectivityStatus.DISCONNECTED,
  });

  return (
    <Section header="Offline Mode">
      <Select
        style={{ width: '280px' }}
        defaultValue={state.tu__connectivity_status}
        onChange={status => {
          setState({ tu__connectivity_status: status });
        }}
        data-testid="tu--connectivity-status-select"
        options={[
          {
            value: InternetConnectivityStatus.DISCONNECTED,
            label: InternetConnectivityStatus.DISCONNECTED,
          },
          {
            value: InternetConnectivityStatus.CONNECTED,
            label: InternetConnectivityStatus.CONNECTED,
          },
        ]}
        majorStyle
      />
      <Button
        size="major"
        onClick={() => {
          window.impr?._config.sendNetworkConnectivityStatus(
            state.tu__connectivity_status,
          );
        }}
        data-testid="tu--connectivity-status-send-button"
      >
        Send
      </Button>
    </Section>
  );
};
