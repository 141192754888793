import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContextFromImprCodingCtxHeader } from '@imprivata-cloud/data-privacy-js';

import type { ApiError, AppError } from '@imprivata-cloud/authn';
import { FatalError } from '@imprivata-cloud/authn';
import { AuthBannerType } from '@imprivata-cloud/components';
import type { AuthBannerProps } from '@imprivata-cloud/components';

import { useTranslation } from 'react-i18next';
import type { RootState } from './store/types';
import { WEB_APP_NAME } from './shared/constants';
import {
  extractUsernameFromContext,
  isDesktopAccess,
  isEpicOauthFlow,
} from './utils/utils';
import { getCredentialsService } from './services/getCredentials';
import { tracer } from './tracing';
import { showWindow } from './agent-api';
import { ShowWindowOption } from './agent-api/types';
import { debug, log } from './utils/logging';

export const useRequestConfig = () => {
  const { appAccessContext, pkceData } = useSelector(
    ({ appAccessContext, pkceData }: RootState) => ({
      appAccessContext,
      pkceData,
    }),
  );

  return useMemo(
    () => ({
      ...(extractUsernameFromContext(appAccessContext) || {}),
      oidcRequestData: pkceData || undefined,
      clientName: WEB_APP_NAME,
    }),
    [appAccessContext, pkceData],
  );
};

export const useBootstrap = () => {
  const { appAccessContext, pkceData, authnData } = useSelector(
    ({ appAccessContext, pkceData, authnData }: RootState) => ({
      appAccessContext,
      pkceData,
      authnData,
    }),
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // pkceData is checked in DA flow only
    if (
      !ready &&
      appAccessContext &&
      (pkceData || !isDesktopAccess()) &&
      authnData
    ) {
      debug('[AppAccess::useBootstrap] ready');
      setReady(true);
    }
  }, [ready, appAccessContext, pkceData, authnData]);

  return { ready };
};

/**
 * Delays updating the value's state until the specified period has passed without any further changes to the input value.
 *
 * @param value the value to debounce
 * @param delay the delay time in milliseconds. After this amount of time, the latest value is used
 */
export const useDebounce = (value: unknown, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useRunEpicOauthFlow = () => {
  const epicOauthFlowStarted = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const { success: epicSuccess, failure: epicFailure } = isEpicOauthFlow();

    if (epicOauthFlowStarted.current) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (epicSuccess) {
      debug('Enroll success received, getting credentials', { searchParams });

      // [APM-893]: Fallback to localStorage
      const storedCodingContext = localStorage.getItem('codingContext');
      const codingContext =
        (storedCodingContext &&
          createContextFromImprCodingCtxHeader(storedCodingContext)) ||
        undefined;

      log('CODING CONTEXT', codingContext);
      if (!codingContext) {
        debug('[EpicOauthFlow::error] Error (no coding context found)');
        return;
      }

      epicOauthFlowStarted.current = true;

      getCredentialsService({
        tracer,
        codingContext,
      })
        .then(response => {
          debug('[EpicOauthFlow::success] success, exiting', { response });
          showWindow(ShowWindowOption.hide);
        })
        .catch((error: unknown) => {
          debug('[EpicOauthFlow::error] Error (getCredentialsService)', {
            error,
          });
        })
        .finally(() => {
          debug('[EpicOauthFlow::success] removing workflowId');
          localStorage.removeItem('workflowId');
        });
    } else if (epicFailure) {
      const error = searchParams.get('error');
      const errorCode = searchParams.get('errorCode');
      debug('Enroll failed', { searchParams: { error, errorCode } });
    } else {
      debug('No oauth flow detected, skipping...');
    }
  }, [dispatch]);
};

export const useGetEpicOauthFlowError = () => {
  const { t } = useTranslation();

  const SERVER_ERROR = 'server-error';
  const CAPTURE_FAILURE = 'capture-failure';

  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get('error');
  const errorCode = searchParams.get('error_code');

  type GetEpicOauthFlowErrorType = {
    fatalError?: AppError;
    banner?: Partial<AuthBannerProps>;
  };

  const errors: GetEpicOauthFlowErrorType = {
    fatalError: undefined,
    banner: undefined,
  };

  if (error == SERVER_ERROR) {
    errors.fatalError = new FatalError({
      code: error,
    } as unknown as ApiError);
  } else if (error == CAPTURE_FAILURE) {
    switch (errorCode) {
      case 'unavailable':
      case 'unknown':
        errors.banner = {
          type: AuthBannerType.ERROR,
        };
        break;
      case 'already-exists':
      case 'too-many-attempts':
        errors.banner = {
          type: AuthBannerType.ERROR,
          primary: t('learn-credentials.UXID_EPIC_LEARN_FAIL.message'),
          secondary: t('learn-credentials.UXID_EPIC_LEARN_FAIL.description'),
        };
        break;
    }
  }
  return errors;
};
