// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';

import { DISABLE_EA_MOCK } from '../constants';
import { ShowWindowOption } from '../../agent-api/types';
import { TestUtilityWindow } from './window';
import { usePersistentSessionState } from '../hooks';
import { getRequiredParams } from '../utils';
import { OverlayMessage } from './components/OverlayMessage';

export const TestUtility: React.FC = () => {
  const searchParams = getRequiredParams();
  const [windowOpen, setWindowOpen] = usePersistentSessionState<{
    tu__window_opened: boolean;
  }>({ tu__window_opened: Object.values(searchParams).every(Boolean) });

  const [showWindowOption, setShowWindowOption] = useState(
    window.impr?._memory.showWindowOption,
  );

  useEffect(() => {
    window.impr?._config.onShowWindowChange(showWindow => {
      console.log('[TestUtility] onShowWindowChange', { showWindow });
      setShowWindowOption(showWindow);
    });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          overflow: 'auto',
        }}
      >
        {showWindowOption !== ShowWindowOption.hide ? (
          <div data-testid="window-is-shown--marker" />
        ) : (
          <div
            data-testid="window-is-hidden--overlay"
            style={{
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              display: 'flex',
              fontSize: 32,
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              zIndex: 2,
            }}
          >
            <OverlayMessage
              onOpen={() => {
                setWindowOpen({ tu__window_opened: true });
              }}
              onDisable={() => {
                localStorage.setItem(DISABLE_EA_MOCK, 'true');
                setWindowOpen({ tu__window_opened: false });
              }}
            />
          </div>
        )}
        {windowOpen.tu__window_opened && (
          <TestUtilityWindow
            onClose={() => {
              setWindowOpen({ tu__window_opened: false });
            }}
          />
        )}
      </div>
    </div>
  );
};
