import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// THEMES
import './styles/index.less';

import {
  readAppAccessContext,
  readIdentityTokenDataAction,
  readPkceData,
  setInternetConnectivityStatusAction,
  subscribeToAuthResponseAction,
} from './store/actions';
import { isDesktopAccess } from './utils/utils';
import {
  subscribeToCloseWindow,
  subscribeToInternetConnectivityStatus,
} from './agent-api';
import { useRunEpicOauthFlow } from './hooks';
import App from './containers/App';
import { log } from './utils/logging';
import { InternetConnectivityStatus } from './constants';

export const Main = () => {
  const dispatch = useDispatch();

  useRunEpicOauthFlow();

  useEffect(() => {
    dispatch(subscribeToAuthResponseAction());

    subscribeToInternetConnectivityStatus((_, data) => {
      if (isDesktopAccess()) {
        dispatch(
          setInternetConnectivityStatusAction(
            data.event === InternetConnectivityStatus.DISCONNECTED
              ? InternetConnectivityStatus.DISCONNECTED
              : InternetConnectivityStatus.CONNECTED,
          ),
        );
      }
    });

    dispatch(readAppAccessContext());
    dispatch(readIdentityTokenDataAction.request());

    if (isDesktopAccess()) {
      document.documentElement.style.setProperty('--card-border', 'none');
      dispatch(readPkceData());

      subscribeToCloseWindow(() => {
        log(`Close window event received. Do nothing for Desktop Access flow.`);
      });
    }
  }, [dispatch]);

  return <App />;
};

export default Main;
