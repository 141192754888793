import { AUTHN_MODULE_APP_VERSION } from '@imprivata-cloud/authn';
import { COMMON_MODULE_APP_VERSION } from '@imprivata-cloud/common';

export const getVersionInfo = () => {
  return {
    BUILD_VERSION: IMPR_BUILD_VERSION || '',
    APP_VERSION: IMPR_APP_VERSION || '0.0.0',
  };
};

export const getBaseUrl = () => import.meta.env.BASE_URL;

export const getBuildInfo = () => {
  return `(app-access=${IMPR_APP_VERSION}) (authn=${AUTHN_MODULE_APP_VERSION}) (common=${COMMON_MODULE_APP_VERSION})`;
};
