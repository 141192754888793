// Copyright 2023, Imprivata, Inc.  All rights reserved.

import { EMPTY, filter, of, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { option } from 'fp-ts';
import type { Epic } from 'redux-observable';
import type { Action, EpicDependencies, RootState } from '../types';
import { readComponentData, setAuthenticationStatusAction } from '../actions';
import { isEpicAccess } from '../../utils/utils';
import { ComponentId } from '../../agent-api/types';
import {
  AuthenticationReadinessStatusCode,
  AuthenticationResultStatusCode,
} from '../../constants';

export const setAuthenticationStatusEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'setAuthenticationStatus'>
> = (action$, state$, dependencies) => {
  const { setAuthenticationStatus } = dependencies;

  return action$.pipe(
    filter(isActionOf(setAuthenticationStatusAction)),
    switchMap(({ payload }) => {
      const { authnData } = state$.value;

      // If authnData exists skip setting authentication status
      if (
        authnData &&
        option.isSome(authnData) &&
        payload !== AuthenticationResultStatusCode.FAILURE
      ) {
        return EMPTY;
      }

      // Otherwise handle other statuses
      if (
        isEpicAccess() &&
        payload === AuthenticationReadinessStatusCode.READY
      ) {
        return of(readComponentData(ComponentId.DESKTOP_ACCESS));
      } else {
        setAuthenticationStatus(payload);
        return EMPTY;
      }
    }),
  );
};
