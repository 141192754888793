// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { FooterWithLogo } from '../footer-with-logo';
import classes from './PaddedContainer.module.css';

export const PaddedContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className={classes.background}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
      <FooterWithLogo />
    </div>
  );
};
