// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { filter, switchMap, of, catchError, from } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { base64ToJson, base64ToString } from '@imprivata-cloud/data-privacy-js';
import type { Epic } from 'redux-observable';

import type { ApiError } from '../../api/types';
import type {
  Action,
  RootState,
  AuthnRequestContext,
  EpicDependencies,
} from '../types';
import { readSessionDataAction, saveAuthnDataAction } from '../actions';
import { SpanNames, tracer } from '../../tracing';
import { sessionDataToString } from '../../utils/utils';
import { SessionDataKey } from '../../agent-api/types';
import { verifyEnrollment } from '../../api/appAccessWebService';
import { getAndClearSessionData } from '../../agent-api';
import { log } from '../../utils/logging';

export const readSessionDataEpic: Epic<
  Action,
  Action,
  RootState,
  Partial<EpicDependencies>
> = (action$, _, _1) => {
  return action$.pipe(
    filter(isActionOf(readSessionDataAction.request)),
    switchMap(({ payload }) => {
      return from(getAndClearSessionData(payload.keys)).pipe(
        switchMap(sessionData => {
          log('read session data', sessionDataToString(sessionData));
          const authnData = sessionData?.find(
            data => SessionDataKey.authnData in data,
          )?.authnData;

          if (!authnData) {
            return of(readSessionDataAction.success());
          }

          const decodedAuthnData = JSON.parse(
            base64ToString(authnData),
          ) as AuthnRequestContext;
          const decodedFactorData = base64ToJson(decodedAuthnData.factorData);
          const request = {
            factorData: payload.codingContext.encryptJson(decodedFactorData),
          };
          tracer.startSpan(SpanNames.VERIFY_ENROLLMENT);
          log('verify enrollment of session data');

          return from(
            verifyEnrollment(
              request,
              payload.identityToken,
              payload.codingContext,
            ),
          ).pipe(
            switchMap(response => {
              tracer.endSpan(SpanNames.VERIFY_ENROLLMENT, { response });
              log('verify enrollment', 'isEnrolled:', response.isEnrolled);
              if (!response.isEnrolled) {
                return of(saveAuthnDataAction(decodedAuthnData));
              } else {
                return of(readSessionDataAction.success());
              }
            }),
            catchError((error: ApiError) => {
              tracer.endSpan(SpanNames.VERIFY_ENROLLMENT, { error });
              return of(readSessionDataAction.failure());
            }),
          );
        }),
      );
    }),
  );
};
