// Copyright 2022, Imprivata, Inc.  All rights reserved.

import ImprivataLogo from '../../images/imprivata.svg?react';
import classes from './FooterWithLogo.module.css';

export const FooterWithLogo: React.FC = () => {
  return (
    <div className={classes.footer}>
      <ImprivataLogo />
    </div>
  );
};
