// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import type { Action, EpicDependencies, RootState } from '../types';
import { agentEpic } from './agentEpic';
import { readAppAccessContextEpic } from './readAppAccessContextEpic';
import { startApplicationAccessEpic } from './startApplicationAccessEpic';
import { getCredentialsEpic } from './getCredentialsEpic';
import { readPkceDataEpic } from './readPkceDataEpic';
import { processCapturedCredentialsEpic } from './processCapturedCredentialsEpic';
import { processLoginResultEpic } from './processLoginResultEpic';
import { readIdentityTokenDataEpic } from './readIdentityTokenDataEpic';
import { setAuthenticationStatusEpic } from './setAuthenticationStatusEpic';
import { readSessionDataEpic } from './readSessionDataEpic';
import { readComponentDataEpic } from './readComponentDataEpic';

export const rootEpic = combineEpics<
  Action,
  Action,
  RootState,
  EpicDependencies
>(
  agentEpic,
  readAppAccessContextEpic,
  startApplicationAccessEpic,
  getCredentialsEpic,
  processCapturedCredentialsEpic,
  processLoginResultEpic,
  readPkceDataEpic,
  readIdentityTokenDataEpic,
  readSessionDataEpic,
  setAuthenticationStatusEpic,
  readComponentDataEpic,
);
