// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common/types';
import type { ShowWindowOption, WindowStyle } from '../../agent-api/types';
import type { MemorySection } from '.';
import { tracer } from '../../tracing';

export class WindowSection {
  constructor(private readonly _memory: MemorySection) {}

  showWindow2(
    _: string,
    traceContext: TraceContext,
    showWindowOption: ShowWindowOption[],
  ): void {
    tracer.startSpanFromContext('show_window_2', traceContext);
    this._memory.showWindowOption = showWindowOption[0];
    if (this._memory.onShowWindowChangeCallback) {
      this._memory.onShowWindowChangeCallback(this._memory.showWindowOption);
    }
    tracer.endSpan('show_window_2');
  }

  setWindowStyle(
    _: string,
    traceContext: TraceContext,
    windowStyle: WindowStyle[],
  ): void {
    tracer.startSpanFromContext('set_window_style', traceContext);
    this._memory.setWindowStyle = windowStyle[0];
    if (this._memory.onSetWindowStyleChangeCallback) {
      this._memory.onSetWindowStyleChangeCallback(this._memory.setWindowStyle);
    }
    tracer.endSpan('set_window_style');
  }
}
