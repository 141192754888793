import { catchError, filter, from, of, switchMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import type { Secret } from '@imprivata-cloud/data-privacy-js';
import type { Epic } from 'redux-observable';
import type { Action, EpicDependencies, RootState } from '../types';
import type { ApiError } from '../../api/types';
import { processCapturedCredentialsAction } from '../actions';
import { tracer, SpanNames } from '../../tracing';

const SPAN_NAME = SpanNames.PROCESS_CAPTURED_CREDENTIALS;

export const processCapturedCredentialsEpic: Epic<
  Action,
  Action,
  RootState,
  Pick<EpicDependencies, 'processCapturedCredentials' | 'sendAuthnDataToAgent'>
> = (action$, _state$, dependencies) => {
  const { processCapturedCredentials, sendAuthnDataToAgent } = dependencies;

  return action$.pipe(
    filter(isActionOf(processCapturedCredentialsAction.request)),
    switchMap(({ payload }) => {
      tracer.startSpan(SPAN_NAME);
      const { codingContext, username, password, isPasswordCaptureOnly } =
        payload;
      const passwordSecret: Secret = codingContext.encryptString(password);

      return from(
        processCapturedCredentials({
          // BE will respond with 500 if you send username on capture-password
          username: isPasswordCaptureOnly ? '' : username,
          password: passwordSecret,
        }),
      ).pipe(
        switchMap(response => {
          tracer.endSpan(SPAN_NAME, { response });
          if (response.credentialData?.data) {
            const credentialData = codingContext.decryptToJson(
              response.credentialData,
            );
            sendAuthnDataToAgent(credentialData);
            return of(processCapturedCredentialsAction.success());
          } else {
            return of(processCapturedCredentialsAction.failure());
          }
        }),
        catchError((error: ApiError) => {
          tracer.endSpan(SPAN_NAME, { error });
          return of(processCapturedCredentialsAction.failure());
        }),
      );
    }),
  );
};
